<template>
  <div>
    <div class="w-full text-xs font-normal mb-2">
      {{ $t("CURRENT_SELECTION") }}
    </div>

    <div class="flex justify-between font-semibold text-sm">
      <span v-if="language != 'ar'">{{ selectedColorName.name }}</span>
      <span v-else>{{ selectedColorName.name_ar }}</span>
    </div>

    <ul class="mt-10" v-if="grades.wheel">
      <div
        v-for="(grade, index) in grades.colors"
        :key="index"
        :class="[grade.spot ? '' : 'hidden']"
      >
        <li v-if="grade.spot">
          <Box
            :grade="grade"
            :extPath="grades.wheel[0].path"
            :selectedColorName="selectedColorName"
          />
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import Box from "../colors/box";
export default {
  props: ["grades", "selectedColorName"],
  components: { Box },
  data() {
    return {
      colors: [],
    };
  },
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
  },
};
</script>

<style scoped>
ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(29%, 1fr));
  grid-gap: 15px;
}
li {
  width: 100%;
  display: inline-block;
  position: relative;
}
@media (max-width: 960px) {
  ul {
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  }
}
</style>
