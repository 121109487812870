<template>
  <div class="buttonWrap">
    <button
      v-if="!confirm"
      @click="
        setSelectedObj([1, carid]);
        tag();
      "
      class="w-full relative bg-herored text-white text-left text-sm selectBtn"
    >
      <span class="text-white"> {{ $t("SELECT") }}</span
      ><span
        ><i
          class="fas  text-white"
          :class="[language != 'ar' ? 'fa-angle-right' : 'fa-angle-left']"
        ></i>
      </span>
    </button>
    <button
      v-if="confirm"
      @click="
        setSelectedObj([1, carid]);
        tag();
      "
      class="w-full relative bg-primaryGrey text-herored  hover:bg-secondaryGrey text-left text-sm selectBtn"
    >
      <span class="text-red-600	"> {{ $t("CONFIRM") }} </span
      ><span
        ><i
          class="fas  text-herored"
          :class="[language != 'ar' ? 'fa-angle-right' : 'fa-angle-left']"
        ></i>
      </span>
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: ["carid", "confirm"],
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
  },
  methods: {
    ...mapActions(["changePage"]),
    ...mapActions(["setSelectedObj"]),
    tag() {
      var model = this.$store.getters.getallCars.model;
      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: 0,
        type: "selection",
        buttonText: "Select",
        item_slection:
          "versions|" + this.$store.state.Cars.selectedGradeObj.Version_Label,
        location: "cta",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },
};
</script>

<style scoped>
button {
  min-width: 210px;
  padding: 0.85714em 20px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 960px) {
  .buttonWrap {
    width: 100%;
  }
}
</style>
