<template>
  <div class="accordion">
    <div class="header" v-on:click="toggle">
      <span name="header" class="text-base font-semibold">{{
        $t("HIGHLIGHTS")
      }}</span>
      <i
        class="fa fa-angle-down header-icon"
        v-bind:class="{ rotate: show }"
      ></i>
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="body" v-show="show">
        <div class="body-inner">
          <slot>
            <ul class="mt-1">
              <li class="relative">
                <span class="opacity-75"
                  ><i class="fa fa-check" aria-hidden="true"></i
                ></span>
                <div class="font-medium pl-2">
                  {{ car.technical_specs.Engine_Type.values[0].value }}
                </div>
              </li>
              <li class="relative">
                <span class="opacity-75"
                  ><i class="fa fa-check" aria-hidden="true"></i
                ></span>
                <div class="font-medium pl-2" v-if="car.wheel.length">
                  {{ car.wheel[0].name }}
                </div>
              </li>
              <li
                class="relative"
                v-for="(higts, index) in car.USPS"
                :key="index"
              >
                <span class="opacity-75"
                  ><i class="fa fa-check" aria-hidden="true"></i
                ></span>
                <div class="font-light pl-5" v-if="language != 'ar'">
                  {{ higts.name }}
                </div>
                <div class="font-light pr-5" v-else>
                  {{ higts.name_ar }}
                </div>
              </li>
              <FeaturesButton
                v-on:featuresClicked="featuresPanel()"
                class="md-custom:mt-4"
              />
            </ul>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FeaturesButton from "./featuresButton";
export default {
  components: {
    FeaturesButton,
  },
  props: ["accordinIsOpen", "car"],
  data() {
    return {
      show: false,
      accordinComponent: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
  },
  methods: {
    featuresPanel: function() {
      this.$emit("featuresClicked");
    },
    toggle: function() {
      this.show = !this.show;
      this.accordinComponent = !this.show;
    },
    beforeEnter: function(el) {
      el.style.height = "0";
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function(el) {
      el.style.height = "0";
    },
  },
  created() {},
};
</script>

<style scoped>
.header {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px;
  font-size: 1.14286em;
  line-height: 1.2;
  text-transform: uppercase;
}

.accordion .header-icon {
  transform: rotate(0deg);
  transition-duration: 0.3s;
  font-size: 22.3px;
  color: #c3002f;
}

.accordion .body {
  /*   display: none; */
  overflow: hidden;
  background-color: #fff;
  transition: 150ms ease-out;
}

.accordion .body-inner {
  padding: 3px;
  overflow-wrap: break-word;
}

.accordion .header-icon.rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
ul li {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #343434;
  margin-bottom: 0.75em;
  line-height: 1.3;
}
ul li i {
  font-size: 12px;
}
</style>
