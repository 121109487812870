<template>
  <div>
    <button
      v-tooltip="{ content: `<h2>${grade.name}</h1>`, classes: 'option' }"
      @click="selectInt(grade, type, index)"
      :class="
        selectedIntColor.name == grade.name ? 'item selected-item' : 'item'
      "
    >
      <div class="absolute inset-0 w-full h-full">
        <img
          :src="grade.spot_image"
          class="absolute inset-0 w-full h-full"
          alt=""
        />
      </div>
      <div class="itemHeader"></div>
    </button>
    <div
      v-if="
        clickedInteriorName == grade.name &&
          initialLoad == false &&
          type == 'all_interior'
      "
    >
      <ChoiceBox
        :allCars="allCars"
        :selectedIntColor="selectedIntColor"
        :data="grade"
        :type="type"
        v-on:triggeredcloseChoice="closeChoiceBox()"
      />
    </div>
  </div>
</template>

<script>
import ChoiceBox from "./choiceBox";
export default {
  props: ["selectedIntColor", "grade", "type", "index", "allCars"],
  data() {
    return {
      showChoice: true,
      initialLoad: true,
      colorName: "",
    };
  },
  computed: {
    clickedInteriorName() {
      return this.$store.state.Pages.clickedInteriorName;
    },
  },
  methods: {
    selectInt(grade, type) {
      this.initialLoad = false;
      this.colorName = "";
      if (type == "model_interior") {
        this.$store.dispatch("setSelectedIntColor", grade);

        var model = this.$store.getters.getallCars.model;
        const analytics = {
          language: this.$store.state.Pages.language,
          model: model?.model,
          object: this.$store.state.Cars.selectedGradeObj,
          currentpage: this.$store.state.Pages.currentPage,
          item_slection: this.$store.state.Pages.selectedIntColors.name,
          type: "selection",
          buttonText: "upholstery",
          location: "selection",
          exteriorColor: this.$store.state.Pages.selectedExtColors,
          interiorColor: this.$store.state.Pages.selectedIntColors,
          wheel: this.$store.state.Pages.selectedWheelName,
        };
        this.$helpers.tag(analytics);
      } else {
        //for setting clicked interiror name in state so that we can show choice box related to

        this.$store.commit("setClickedInt", grade.name);
      }
    },
    closeChoiceBox() {
      //cancel button click, hide choice box, pass empty value to state
      this.$store.commit("setClickedInt", "");
    },
  },
  components: {
    ChoiceBox,
  },
  created() {},
};
</script>

<style scoped>
@import url("tooltip.css");
button.item {
  position: relative;
  padding-top: 57%;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 4px;
  transition-property: transform, box-shadow;
  transition-duration: 0.3s;
  pointer-events: all;
  color: #fff;
  outline: none;
}
.item.selected-item:before {
  border-width: 2px;
  border-color: #c3002f;
  outline: none;
}
button.item:before {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  width: 100%;
  height: 100%;
  content: "";
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: border 0.3s;
  z-index: 1;
  border-width: 2px;
}
button.item:hover {
  box-shadow: 0 15px 8px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.selected-item .itemHeader {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.selected-item .itemHeader:before {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 43px 43px 0;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
  border-right-color: #c3002f;
}
.selected-item .itemHeader h2 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.selected-item.item:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 6px;
  right: 7px;
  left: auto;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
@media (max-width: 960px) {
  ul {
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  }
}
</style>
