const state = {
  currentPage: 0,
  language: "en",
  isLoad: false,
  isLoadInterior: false,
  selectedExtColors: {},
  selectedIntColors: {},
  clickedInteriorName: "", //temprory state for checking choice box in interior
  selectedWheelName: "",
  uri: {}, // path after #
  alredySelected: false,
  summaryUrl: "",
};

const mutations = {
  changeAlreadySelected(state) {
    state.alredySelected = true;
  },
  changePage(state, id) {
    state.currentPage = id;
  },
  setSelectedColor(state, value) {
    state.selectedExtColors = value;
  },
  setSelectedIntColor(state, value) {
    state.selectedIntColors = value;
  },
  setExtLoader(state) {
    state.isLoad = !state.isLoad;
  },
  setIntLoader(state) {
    state.isLoadInterior = !state.isLoadInterior;
  },
  setClickedInt(state, value) {
    state.clickedInteriorName = value;
  },
  setSummaryUrl(state, value) {
    if (value != "") {
      state.summaryUrl = value;
    }
  },
  setSelectedWheel(state, value) {
    state.selectedWheelName = value;
  },
};
const getters = {
  changeAlreadySelected: (state) => state.alredySelected,
  currentPage: (state) => state.currentPage,
  selectedColors: (state) => state.selectedExtColors,
  selectedIntColors: (state) => state.selectedIntColors,
  isLoading: (state) => state.isLoad,
  isLoadInterior: (state) => state.isLoadInterior,
};

const actions = {
  changeAlreadySelected({ commit }) {
    commit("changeAlreadySelected");
  },
  changePage({ commit }, value) {
    let uriParams = document.location.href.split("#");
    let uriParam = uriParams[1];

    if (uriParam) {
      uriParams = uriParam.split("/");
      var model = uriParams[0] ? uriParams[0] : "";
      var grade = uriParams[1] ? uriParams[1] : "";
      var extcol = uriParams[2] ? uriParams[2] : "";
      var intcol = uriParams[3] ? uriParams[3] : "";
      var page = value;
    }
    const urlpath =
      "#" +
      model.replace(/ /g, "_") +
      "/" +
      grade.replace(/ /g, "_") +
      "/" +
      extcol.replace(/ /g, "_") +
      "/" +
      intcol.replace(/ /g, "_") +
      "/" +
      page;
    document.location.href = urlpath;

    commit("changePage", value);
    // let el = document.body;

    commit("setSummaryUrl", urlpath);

    // if (state.currentPage == 0 || state.currentPage == 4) {
    //   el.classList.remove("disableScroll");
    //   el.classList.add("enableScroll");
    // } else {
    //   el.classList.remove("enableScroll");
    //   el.classList.add("disableScroll");
    // }
  },
  setSelectedColor({ commit, rootState }, value) {
    commit("setExtLoader");
    setTimeout(() => {
      commit("setExtLoader");
    }, 100);
    commit("setSelectedColor", value);
    let uriParams = document.location.href.split("#");
    let uriParam = uriParams[1];

    if (uriParam) {
      uriParams = uriParam.split("/");

      var page = uriParams[4] ? uriParams[4] : "";
    }

    var parsedobj = JSON.parse(JSON.stringify(rootState));
    if (Object.keys(parsedobj.Cars.cars).length > 0) {
      var smodel = parsedobj.Cars.cars.model.model.toLowerCase();
      var sgrade = parsedobj.Cars.selectedGradeObj.Version_Label;
      var scolorExterior = parsedobj.Pages.selectedExtColors.key;
      var scolorinterior = parsedobj.Pages.selectedIntColors.key;

      const urlpath =
        "#" +
        smodel.replace(/ /g, "_") +
        "/" +
        sgrade.replace(/ /g, "_") +
        "/" +
        scolorExterior.replace(/ /g, "_") +
        "/" +
        scolorinterior +
        "/" +
        page;
      document.location.href = urlpath;
      commit("setSummaryUrl", urlpath);
    }
  },
  setSelectedIntColor({ commit, rootState }, value) {
    let uriParams = document.location.href.split("#");
    let uriParam = uriParams[1];
    var page = 0;
    if (uriParam) {
      uriParams = uriParam.split("/");

      page = uriParams[4] ? uriParams[4] : "";
    }
    commit("setIntLoader");
    setTimeout(() => {
      commit("setIntLoader");
    }, 900);
    commit("setSelectedIntColor", value);

    var parsedobj = JSON.parse(JSON.stringify(rootState));
    if (Object.keys(parsedobj.Cars.cars).length > 0) {
      var smodel = parsedobj.Cars.cars.model.model.toLowerCase();
      var sgrade = value.grades[0];
      var scolorExterior = parsedobj.Pages.selectedExtColors.key;
      var scolorinterior = parsedobj.Pages.selectedIntColors.key;
      const urlpath =
        "#" +
        smodel.replace(/ /g, "_") +
        "/" +
        sgrade.replace(/ /g, "_") +
        "/" +
        scolorExterior.replace(/ /g, "_") +
        "/" +
        scolorinterior +
        "/" +
        page;
      document.location.href = urlpath;
      commit("setSummaryUrl", urlpath);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
