var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"optionChoices",class:[_vm.language != 'ar' ? 'optionChoices_en' : 'optionChoices_ar']},[_c('span',{class:[_vm.language != 'ar' ? 'arrow arrow_en' : 'arrow arrow_ar']}),_c('div',{staticClass:"choicesInner"},[_c('h2',{staticClass:"text-base font-semibold uppercase text-white"},[_vm._v(" "+_vm._s(_vm.$t("REMOVE_SELECTION"))+" ")]),_c('ul',{staticClass:"mt-4"},[_c('li',{staticClass:"w-full flex justify-between mb-1"},[_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.allCars.grades[_vm.selectedIntColor.grades[0].replace(/ /g, "_")] .Version_Label))]),_c('span',{staticClass:"text-sm flex  text-white"},[_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-1' : 'text-white order-2' ]},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-2' : 'text-white order-1' ]},[_vm._v(_vm._s(Number( _vm.allCars.grades[_vm.selectedIntColor.grades[0].replace(/ /g, "_")] .price ).toLocaleString())+" ")])])])])]),_c('div',{staticClass:"mt-4 choicesInner"},[_c('h2',{staticClass:"text-base font-semibold uppercase text-white"},[_vm._v(" "+_vm._s(_vm.$t("REPLACE_SELECTION"))+" ")]),_c('ul',{staticClass:"mt-4"},[_c('li',{staticClass:"w-full flex justify-between mb-1"},[_c('span',{staticClass:"text-sm  text-white"},[_vm._v(" "+_vm._s(_vm.allCars.grades[_vm.data.grades[0].replace(/ /g, "_")].Version_Label))]),_c('span',{staticClass:"text-sm flex  text-white"},[_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-1' : 'text-white order-2' ]},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-2' : 'text-white order-1' ]},[_vm._v(_vm._s(Number( _vm.allCars.grades[_vm.data.grades[0].replace(/ /g, "_")].price ).toLocaleString())+" ")])])])])]),_c('div',{staticClass:"mt-4 choicesInner"},[_c('h2',{staticClass:"flex justify-between text-base font-semibold uppercase pb-4 border-0 border-bw-1 border-solid border-gray-400"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t("NEW_TOTAL")))]),_c('i',{staticClass:"text-white flex"},[_c('span',{class:[
            _vm.language == 'en' ? 'text-white order-1' : 'text-white order-2' ]},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[
            _vm.language == 'en' ? 'text-white order-2' : 'text-white order-1' ]},[_vm._v(_vm._s(Number( _vm.allCars.grades[_vm.data.grades[0].replace(/ /g, "_")].price ).toLocaleString())+" ")])])]),_c('div',{staticClass:"flex justify-between mt-4 btnWrap"},[_c('button',{staticClass:"w-full text-sm font-normal uppercase  relative cancel",class:[_vm.language != 'ar' ? 'mr-4' : 'ml-4'],on:{"click":function($event){return _vm.closeChoiceBox()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("CANCEL"))+" ")]),_c('i',{staticClass:"   absolute right-0 text-base",class:[
            _vm.language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left' ],style:(_vm.language != 'ar' ? 'right: 22px' : 'left: 22px')})]),_c('button',{staticClass:"w-full text-sm font-normal uppercase relative   bg-herored text-white",on:{"click":function($event){_vm.confirm(_vm.data.grades[0].replace(/ /g, '_'))}}},[_c('i',{staticClass:"text-white absolute right-0 text-base",class:[
            _vm.language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left' ],style:(_vm.language != 'ar' ? 'right: 22px;' : 'left: 22px; ')}),_c('span',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("CONFIRM"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }