<template>
  <div
    class="flex md-custom-min:flex-row md-custom:flex-col-reverse flex-wrap overflow-hidden w-full    colorsWrap" :style="{'height':filterSectionHeight}"
  >
    <section
      class="flex flex-col colorLeft"
      v-show="colorComponent"
      :colorsIsOpen="colorComponent"
    >
      <div
        class="relative overflow-x-hidden md-custom-min:overflow-y-scroll p-5 w-full   innerLeft"  :style="{'height':filterSectionHeight}"
      >
        <ColorBox
          v-bind:grades="getGradObj"
          v-bind:selectedColorName="selectedExtColorName"
        />
        <NextButton />
      </div>
    </section>

    <section class="flex-auto relative bg-white md-custom:hidden">
      <div class="flex-auto w-full h-full previewWrap">
        <div class="absolute inset-0 w-full h-full cursor-grab">
          <div class="In_Ex_View" v-if="Object.keys(getGradObj).length">
            <transition name="fade">
              <PreviewBox
                v-bind:grades="getGradObj"
                v-bind:selectedColor="selectedExtColor"
                v-if="interior && !IsLoading"
              />
              <div class="fa-3x loader" v-if="IsLoading">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </transition>

            <Interior
              v-bind:grades="getGradObj"
              v-bind:selectedIntColor="selectedIntColor"
              :page="'colors'"
              v-if="exterior && !isLoadInterior"
            />
          </div>

          <div
            class="toggleBttns"
            :style="language != 'ar' ? 'right: 0;left: auto;' : 'right: auto;'"
          >
            <ViewSwitch @click.native="toggleView()" />

            <InExView @click.native="swapComponent" />
          </div>
          <Popover v-bind:grades="getGradObj" />
        </div>
      </div>
    </section>

    <section class="flex  md-custom-min:hidden md-custom:flex">
      <div class="w-2/4 wrapSec">
        <div class="inner">
          <NamePrice />
        </div>
      </div>
      <div class="w-2/4 wrapSec">
        <div class="inner">
          <ImageThumbnail v-on:popupClicked="popupOpen()" />
        </div>
      </div>

      <VehiclePopup v-if="vehiclePopup" v-on:popupClosed="popupClose()" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ColorBox from "./colorBox";
import PreviewBox from "./360";
import Interior from "./interior";
import NextButton from "../../../components/nextStepButton/nextButton";
import ViewSwitch from "../../../components/toggleButtons/toggleView";
import InExView from "../../../components/toggleButtons/in_exView";
import Popover from "../../../components/pricePopover/detail";
import NamePrice from "../../../components/smallScreen360-carDetails/namePrice";
import ImageThumbnail from "../../../components/smallScreen360-carDetails/imageThumbnail";
import VehiclePopup from "../../../components/smallScreen360-carDetails/vechiclePopup";
export default {
  props: [],
  data() {
    return {
      colorComponent: true,
      interior: true,
      exterior: false,
      vehiclePopup: false,
      windowHeight:window.innerHeight
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     console.log(`it changed to ${newHeight} from ${oldHeight}`);
    }
  },


  computed: {
 filterSectionHeight()
    {

      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!isMobile) {
       var width = document.body.clientWidth;
       return (width / 1.77777777778)/1.7 + 'px';
      }else{
        return '100%';
      }
    
       
    },
    language() {
      return this.$store.state.Pages.language;
    },
    IsLoading() {
      return this.$store.getters.isLoading;
    },
    isLoadInterior() {
      return this.$store.getters.isLoadInterior;
    },

    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    selectedExtColorName() {
      return this.$store.getters.selectedColors;
    },
    ...mapGetters(["getGradObj"]),
    //...mapGetters(['getGrade']),
  },
  methods: {

 filterSectionHeights()
    {
     var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!isMobile) {
       var width = document.body.clientWidth;
       return (width / 1.77777777778)/1.7+ 'px';
      }else{
        return '100%';
      }
    },
    toggleView() {
      this.colorComponent = !this.colorComponent;
    },

    swapComponent() {
      if (this.interior == true) {
        this.interior = false;
        this.exterior = true;
      } else {
        this.interior = true;
        this.exterior = false;
      }
    },
    popupOpen() {
      this.vehiclePopup = !this.vehiclePopup;
    },
    popupClose() {
      this.vehiclePopup = !this.vehiclePopup;
    },
  },

  created() {
    this.interior = true;
     window.addEventListener("resize", this.filterSectionHeights);

   
  },
  destroyed() {
  window.removeEventListener("resize", this.filterSectionHeights);
},

  components: {
    ColorBox,
    PreviewBox,
    NextButton,
    Interior,
    ViewSwitch,
    InExView,
    Popover,
    NamePrice,
    ImageThumbnail,
    VehiclePopup,
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.colorsWrap section.colorLeft {
  flex: 0 0 385px;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.wrapSec {
  border-bottom: 1px solid #c1c1c1;
  background: #fff;
}
@media (max-width: 960px) {
  .colorLeft {
    flex: auto !important;
    width: 100%;
  }
  .heroHead h2 {
    font-size: 0.5em;
    font-weight: 300;
  }
}
</style>
