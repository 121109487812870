<template>
  <div class="extColors">
    <div v-for="(color, index) in car.colors" :key="index">
      <img v-if="color.spot" :src="color.spot" class="" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["car"],
};
</script>

<style>
.extColors {
  display: flex;
}
.extColors img {
  border-radius: 50%;
  height: 21px;
  width: 21px;
  margin-right: 5px;
}
</style>
