<template>
  <div>
    <vue-three-sixty
      :amount="amount"
      :imagePath="selectedColor"
      :paddingIndex="true"
      fileName="{index}.jpg"
    >
      <template v-slot:header>
        <div class="v360-header text-light bg-dark">
          <!-- <span class="v360-header-title">36 Images</span> -->
          <span class="v360-header-description"></span>
        </div>
      </template>
    </vue-three-sixty>
  </div>
</template>

<script>
export default {
  props: ["grades", "selectedColor"],
  data() {
    return {
      amount: 36,
      path: "",
    };
  },
};
</script>

<style>
.v360-navigate-btns,
.v360-fullscreen-toggle {
  display: none;
}

.v360-percentage-text {
  color: #fff;
}
.v360-viewport {
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  /* transition: width 0.3s ease; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.v360-image-container {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
}
.v360-image-container img {
  width: 100%;
}
.In_Ex_View .v360-viewport {
  position: relative;
}
</style>
