<template>
  <div class="flex justify-between align-middle mb-3">
    <div>
      <h2 class="font-semibold">
        {{ $t("VERSIONS") }}
        <h3 class="font-light">
          {{ $t("CURRENT_SELECTION") }} :

          <span class="model-name mr-1" v-if="name && language == 'en'">
            {{ name.model }}</span
          >
          <span class="model-name mr-1" v-else-if="name && language == 'ar'">
            {{ name.model_ar }}</span
          >
          <span class="grade-name">{{ getGradObj.Version_Label }}</span>
        </h3>
      </h2>
    </div>
    <div class="flex items-center pricefilter md-custom:hidden">
      <label
        :style="
          language != 'ar' ? '  margin-right: 13px;' : '  margin-left: 13px;'
        "
        >{{ $t("SORT.LABEL") }}</label
      >
      <select
        class="customSelect"
        v-model="type"
        @change="sort"
        :style="language != 'ar' ? 'padding-right: 3em;' : 'padding-left: 3em;'"
      >
        <option value="lth">{{ $t("SORT.LTH") }} </option>
        <option value="htl">{{ $t("SORT.HTL") }} </option>
      </select>
      <span
        :style="
          language != 'ar'
            ? '  right: 0;  border-left: 1px solid #eee;'
            : 'left:0;  border-right: 1px solid #eee;'
        "
        ><i class="fas fa-angle-down"></i
      ></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "versionHead",
  props: ["car", "selectedModel", "name"],
  data() {
    return {
      type: "lth",
    };
  },
  computed: {
    ...mapGetters(["getGradObj"]),
    language() {
      return this.$store.state.Pages.language;
    },
  },
  methods: {
    sort() {
      this.$store.commit("setSort", this.type);
      var model = this.$store.getters.getallCars.model;

      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: "sort",
        buttonText:
          this.type == "lth" ? "Price low to high" : "Price high to low",
        location: "sort",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },
};
</script>

<style scoped>
h2 {
  margin-bottom: 0.33333em;
  font-size: 18px;
  color: #343434;
  letter-spacing: 0.1px;
  line-height: 1;
}
.model-name {
  text-transform: uppercase;
}
.pricefilter {
  position: relative;
}
label {
  font-size: 0.75714em;
  font-weight: 600;
}
h3 {
  font-size: 0.85em;
  margin-top: 6.1px;
}
h3 span {
  font-weight: 600;
}
.pricefilter span {
  position: absolute;

  top: 0;
  width: 31px;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
