import Vuex from "vuex";
import Vue from "vue";
import Cars from "./modules/cars";
import Pages from "./modules/pages";

// Load Vuex
Vue.use(Vuex);

// Create Store

export default new Vuex.Store({
  modules: {
    Cars,
    Pages,
  },
});
