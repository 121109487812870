<template>
  <div
    class="imageThumbnail cursor-pointer"
    v-on:click="popupOpen()"
    :style="
      language != 'ar'
        ? 'border-left: 1px solid #c1c1c1;'
        : '  border-right: 1px solid #c1c1c1;'
    "
  >
    <figure>
      <img
        v-if="currentPage != 2"
        :src="selectedExtColor + '/01.jpg'"
        class="w-full ext"
        alt=""
      />
      <img v-else :src="selectedIntColor.image_url" class="w-full int" alt="" />
    </figure>
    <!-- <figure v-else>
      <img :src="selectedExtColor + '/01.jpg'" class="w-full ext" alt="" />
    </figure> -->
    <div class="viewIcon">View</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    ...mapGetters(["currentPage"]),
    ...mapGetters(["getallCars"]),
  },
  methods: {
    popupOpen() {
      this.$emit("popupClicked");
    },
  },
};
</script>

<style scoped>
.imageThumbnail {
  position: relative;
}
.viewIcon {
  font-size: 0.75em;
  color: #343434;
  text-align: left;
  display: block;
  padding-left: 8px;
  padding: 8px;
  position: absolute;
  background: hsla(0, 0%, 100%, 0.7);
  bottom: 0px;
  width: 100%;
}
.viewIcon:after {
  content: "\f424";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #c3002f;
  margin-left: 4px;
}
figure {
  width: 100%;
  height: 150px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
figure .int {
  transform: scale(5, 5);
}
figure .ext {
  transform: scale(1.4);
  position: relative;
  top: 26px;
}
figure img {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  opacity: 1;
  max-width: 100%;
  max-height: 100%;

  overflow: hidden;
}
</style>
