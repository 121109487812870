<template>
  <div class="buttonWrap">
    <button
      @click="choiceBoxOpen()"
      class="w-full relative bg-herored text-white text-left text-sm choiceBtn hover:bg-darkRed"
    >
      SELECT<span>
        <i class="fas fa-exclamation-triangle text-white text-sm"></i
      ></span>
    </button>

    <!-- <router-link to="/colors" class="w-full relative bg-herored text-white text-left text-sm selectBtn" tag="button">SELECT<span><i class="fas fa-angle-right text-white"></i></span></router-link> -->
  </div>
</template>

<script>
//import {  mapActions} from "vuex";

export default {
  methods: {
    choiceBoxOpen() {
      this.$emit("choiceTriggered");
    },
  },
};
</script>

<style scoped>
button {
  min-width: 210px;
  padding: 0.85714em 20px;
  line-height: 1.3;
}
span {
  position: absolute;
  right: 11px;
  font-size: 17px;
}
@media (max-width: 960px) {
  .buttonWrap {
    width: 100%;
  }
}
</style>
