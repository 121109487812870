<template>
  <div class="flex engineDetails items-center mobbbb">
    <div
      class="flex items-center w-full"
      v-if="Object.keys(car.technical_specs).length"
    >
      <img src="@/assets/img/engine.png" class="engine-icon" />
      <div
        class="font-semibold eValue"
        v-if="car.technical_specs.Engine_Type.values"
      >
        {{ car.technical_specs.Engine_Type.values[0].value }}
      </div>
    </div>

    <span v-if="car.technical_specs.Transmission_Type.values">{{
      car.technical_specs.Fuel_Type.values[0].value
    }}</span>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  props: ["car", "name"],
  data() {
    return {};
  },

  computed: {
    ...mapGetters(["getallCars"]),
  },

  created() {},
};
</script>

<style scoped>
.engineDetails {
  flex-direction: column;
    width: 100%;
    padding-left: 2em;
    line-height: 1.4;
}
.engineDetails > span {
  font-size: 14px;
      width: 100%;
}
.eValue {
  font-size: 0.875em;
}
.engine-icon {
   height: 20px;
  position: absolute;
  left: 0;
  right: auto;
}
</style>
