<template>
  <div>
    <div>
      <div
        class="flex flex-wrap relative overflow-hidden items-center engineDetails"
        :class="[language != 'ar' ? 'engineDetails_en' : 'engineDetails_ar']"
      >
        <div
          class="flex items-center"
          v-if="Object.keys(car.technical_specs).length"
        >
          <img
            src="@/assets/img/engine.png"
            class="engine-icon"
            :style="language != 'ar' ? 'left: 0;right: auto' : 'right: auto'"
          />
          <div
            class="font-semibold eValue"
            :style="
              language != 'ar' ? 'padding: 0 9px 0 0;' : 'padding: 0 16px 0 0;'
            "
            v-if="language != 'ar'"
          >
            {{ car.technical_specs.Engine_Type.values[0].value }}
          </div>
          <div
            class="font-semibold eValue"
            :style="
              language != 'ar' ? 'padding: 0 9px 0 0;' : 'padding: 0 16px 0 0;'
            "
            v-else
          >
            {{ car.technical_specs.Engine_Type.values[0].value_ar }}
          </div>
        </div>
        <span v-if="car.technical_specs && language != 'ar'">{{
          car.technical_specs.Fuel_Type.values[0].value
        }}</span>
        <span v-else>{{
          car.technical_specs.Fuel_Type.values[0].value_ar
        }}</span>

        <span
          v-if="
            car.technical_specs.Transmission_Type.values && language != 'ar'
          "
          >{{ car.technical_specs.Transmission_Type.values[0].value }}</span
        >
        <span
          v-else-if="
            car.technical_specs.Transmission_Type.values && language == 'ar'
          "
          >{{ car.technical_specs.Transmission_Type.values[0].value_ar }}</span
        >
      </div>

      <div class=" priceDetail items-center">
        <div>
          <span class="v-price-label-value">{{ $t("STARTING_FROM") }}</span>
        </div>
        <div>
          <span class="font-light v-price-amount flex">
            <span :class="[language == 'en' ? 'order-1' : 'order-2']">{{
              $t("CURRENCY")
            }}</span>
            <span :class="[language == 'en' ? 'order-2' : 'order-1']"
              >{{ Number(car.price).toLocaleString() }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters } from "vuex";
export default {
  props: ["car", "name"],
  data() {
    return {};
  },

  computed: {
    ...mapGetters(["getallCars"]),
    language() {
      return this.$store.state.Pages.language;
    },
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
  },

  created() {},
};
</script>

<style scoped>
.engineDetails {
  padding-left: 1.1em;
  line-height: 1.4;
}
.engineDetails > span {
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin: 0 -1px;
  padding: 0 9px 0 0;
  line-height: 1.57142857;
  align-items: center;
}
.eValue {
  font-size: 0.875em;
  display: flex;
  justify-content: center;
  margin: 0 -1px;

  line-height: 1.57142857;
  align-items: center;
}

.eValue::before,
.engineDetails_en > span::before {
  margin: 0 8px 0 0;
}
.eValue::before,
.engineDetails_ar > span::before {
  margin: 0 0 0 8px;
}
.eValue::before,
.engineDetails > span::before {
  display: inline-block;
  width: 1px;
  content: "";

  height: 0.85714em;
  background-color: #d2d2d2;
}
.engine-icon {
  height: 20px;
  position: absolute;
}
.v-price-label-value {
  font-size: 0.71em;
  color: #000;
}
.v-price-amount {
  color: #000;
  font-size: 1.6em;
  line-height: 1;
  margin-bottom: 0.20769em;
  margin-top: 2px;
}

.priceDetail {
  margin-top: 20px;
}
.v-price-description {
  color: #5c5c5c;
  font-size: 0.71429em;
}
</style>
