<template>
  <div class="flex flex-row justify-end bg-white utility">
    <button class="uppercase buttonHero" @click="print">
      <span> {{ $t("PRINT") }}</span>
    </button>
    <div id="printMe">
      <div>
        <div style="display:flex;justify-content:space-between;width:30%">
          <div>
            <img
              src="https://di-uploads-pod2.dealerinspire.com/advantagenissan/uploads/2018/04/logo.png"
            />
          </div>

          <div>
            {{ $t("PRINT") }}
            {{
              new Date()
                .toJSON()
                .slice(0, 10)
                .replace(/-/g, "/")
            }}
          </div>
        </div>
        <h2
          class="uppercase"
          v-if="getallCars.model && language != 'ar'"
          style="font-size: 39px;margin-bottom:0"
        >
          {{ getallCars.model.model }}
        </h2>
        <h2
          class="uppercase"
          v-else-if="getallCars.model && language == 'ar'"
          style="font-size: 39px;margin-bottom:0"
        >
          {{ getallCars.model.model_ar }}
        </h2>
        <h4
          class="text-sm font-semibold uppercase"
          v-if="getGradObj && getGradObj.technical_specs"
          style="font-size: 22px;margin-top:10px"
        >
          {{ getGradObj.technical_specs.Engine_Type.values[0].value }}
          {{ getGradObj.Version_Label }}
        </h4>

        <div>
          <span style="display:block;font-size: 1.3rem;margin-top:18px;">{{
            $t("TOTAL_PRICE")
          }}</span>
          <div style="font-size: 2.5rem;font-weight:500;">
            {{ $t("CURRENCY") }}{{ Number(getGradObj.price).toLocaleString() }}
          </div>
        </div>
        <div
          style="width:40%;display:flex;justify-content:space-between;margin-top:20px;"
        >
          <div style="border:1px solid #e3e2e2;margin-right:20px">
            <img :src="selectedExtColor + '/01.jpg'" style="height:300px" />
          </div>
          <div>
            <img
              :src="selectedIntColor.image_url"
              style="object-fit: cover;
        object-position: -90% -90%;
        zoom:5;
        width: 200px;
        height: 100px;"
            />
          </div>
        </div>
        <div class="features">
          <div class="configuartor">
            <h1>{{ $t("CONFIGURATOR") }}</h1>
            <table
              style="border-collapse: collapse;width: 100%;padding-bottom:1.5em;"
            >
              <tr
                style="border-bottom:1px solid #d2d2d2; padding-top:7px;"
                v-if="getGradObj"
              >
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b> {{ $t("VERSIONS") }}</b>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ getGradObj.Version_Label }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  {{ $t("CURRENCY") }}
                  {{ Number(getGradObj.price).toLocaleString() }}
                </td>
              </tr>
              <tr style="border-bottom:1px solid #d2d2d2; padding-top:7px;">
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b>{{ $t("TABS.COLOURS") }}</b>
                </td>
                <td
                  style="font-size: 22px; padding: 1em 0px;"
                  v-if="selectedExtColorName.name"
                >
                  <span v-if="language != 'ar'">
                    {{ selectedExtColorName.name }}
                  </span>
                  <span v-else> {{ selectedExtColorName.name_ar }} </span>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  {{ $t("INCLUDED") }}
                </td>
              </tr>
              <tr style="border-bottom:1px solid #d2d2d2;padding-top:7px;">
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b>
                    <b>{{ $t("TABS.UPHOLSTERY") }}</b></b
                  >
                </td>
                <td
                  style="font-size: 22px; padding: 1em 0px;"
                  v-if="selectedIntColor.name"
                >
                  {{ selectedIntColor.name }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  {{ $t("INCLUDED") }}
                </td>
              </tr>
              <tr v-if="getGradObj.wheel">
                <td style="font-size: 23px; padding: 1em 0px;">
                  <b>{{ $t("TABS.WHEELS") }} </b>
                </td>
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ getGradObj.wheel[0].name }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("INCLUDED") }}
                </td>
              </tr>
            </table>
          </div>
          <div
            style="width:100%;display:flex;justify-content: flex-end;flex-direction: column; "
          >
            <span
              style="display:block;font-size: 1.3rem;text-align:right;margin-top:18px;"
              >{{ $t("TOTAL_PRICE") }}</span
            >
            <div style="text-align:right; font-size: 2.5rem;font-weight: 500;">
              {{ $t("CURRENCY")
              }}{{ Number(getGradObj.price).toLocaleString() }}
            </div>
          </div>
        </div>
        <div class="features">
          <div class="configuartor">
            <h1 style="display:block; margin-top:1em">
              {{ $t("CONFIGURATOR") }}
            </h1>
            <h3 style="font-size: 1.2rem;">{{ $t("CONSUMPTION") }}</h3>
            <h3 style="font-size: 1.2rem;">{{ $t("ENGINE_CATEGORY") }}</h3>
            <table
              v-if="getGradObj && getGradObj.technical_specs"
              style="border-collapse: collapse;width: 100%;padding-bottom:1.5em;"
            >
              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Engine_Type"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("ENGINE") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">{{
                    getGradObj.technical_specs.Engine_Type.values[0].value
                  }}</span>
                  <span v-else>{{
                    getGradObj.technical_specs.Engine_Type.values[0].value_ar
                  }}</span>
                </td>
              </tr>

              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Max_Engine_Power_hp__rpm"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("MAX") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Max_Engine_Power_hp__rpm
                        .values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Max_Engine_Power_hp__rpm
                        .values[0].value_ar
                    }}</span
                  >
                </td>
              </tr>

              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Capacity_l"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("CAPACITY") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Capacity_l.values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Capacity_l.values[0].value_ar
                    }}</span
                  >
                </td>
              </tr>
              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Number_of_Gears"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("GEARS") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Number_of_Gears.values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Number_of_Gears.values[0]
                        .value_ar
                    }}</span
                  >
                </td>
              </tr>
              <tr
                style="border-bottom:1px solid #d2d2d2;padding-top:7px;"
                class="flex justify-between font-medium text-sm"
                v-if="getGradObj.technical_specs.Fuel_Type"
              >
                <td style="font-size: 22px; padding: 1em 0px;">
                  {{ $t("FUEL") }}
                </td>
                <td style="font-size: 22px; padding: 1em 0px;text-align:right;">
                  <span v-if="language != 'ar'">
                    {{
                      getGradObj.technical_specs.Fuel_Type.values[0].value
                    }}</span
                  >
                  <span v-else>
                    {{
                      getGradObj.technical_specs.Fuel_Type.values[0].value_ar
                    }}</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="features">
          <div class="configuartor">
            <h1>{{ $t("EQUIPMENT") }}</h1>
            <div class="equipment mt-8" v-if="getGradObj.equipments">
              <section
                class="w-full mt-3 mb-6"
                v-for="item in getGradObj.equipments"
                :key="item.id"
              >
                <h2
                  class="uppercase font-semibold text-base"
                  v-if="language != 'ar'"
                >
                  {{ item.name }}
                </h2>
                <h2 class="uppercase font-semibold text-base" v-else>
                  {{ item.name_ar }}
                </h2>
                <ul
                  class="mt-5"
                  v-if="item.values"
                  style="display:block;list-style: none; padding-bottom:1.3em;"
                >
                  <li
                    style="font-size: 24.5px;padding:0.65em 0;border-bottom:1px solid #d2d2d2;"
                    class="w-full text-sm font-medium icoNissan"
                    v-for="itemVal in item.values"
                    :key="itemVal.id"
                  >
                    <span style="font-size:22px;margin-right:7px"
                      >&#10004;</span
                    >
                    <span v-if="language != 'ar'"> {{ itemVal.name }}</span>
                    <span v-else> {{ itemVal.name_ar }}</span>
                  </li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-on:click="featuresOpen('share')"
      class="uppercase buttonHero share"
    >
      <span> {{ $t("SHARE") }}</span>
    </button>
    <button
      class="uppercase mr-0 buttonHero save"
      v-on:click="featuresOpen('save')"
    >
      <span> {{ $t("SAVE") }}</span>
    </button>
    <Features
      v-if="featuresComponent"
      :type="modalType"
      v-on:closeModal="featuresOpen"
      :featuresIsOpen="featuresComponent"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Features from "../../components/sidePanel/panelView";
export default {
  data() {
    return {
      featuresComponent: false,
      modalType: "",
    };
  },
  computed: {
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["getGrade"]),
    ...mapGetters(["getallCars"]),
    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedExtColorSpot() {
      var OBJ = this.$store.getters.selectedColors;
      const data = {
        spot: OBJ.spot,
        name: OBJ.name,
      };
      return data;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    selectedExtColorName() {
      return this.$store.getters.selectedColors;
    },
    language() {
      return this.$store.state.Pages.language;
    },
  },
  methods: {
    print() {
      this.tagging("print", "print", "summary");
      let contents = document.getElementById("printMe").innerHTML;
      let model = document.getElementById("model").innerHTML;
      let frame1 = document.createElement("iframe");
      frame1.name = "frame1";
      frame1.style.position = "absolute";
      frame1.style.top = "-1000000px";
      document.body.appendChild(frame1);
      let frameDoc = frame1.contentWindow
        ? frame1.contentWindow
        : frame1.contentDocument.document
        ? frame1.contentDocument.document
        : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(
        '<html lang="en"><head><title>2020 Nissan ' +
          model +
          "  - Configure Colors, Options & Accessories In 360</title>"
      );

      frameDoc.document.write(
        '<link href="../../assets/css/app.css" rel="stylesheet" type="text/css" />'
      );
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(contents);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      setTimeout(function() {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
      return false;
      // this.showPrint=true;
      // this.$htmlToPaper('printMe');
    },
    featuresOpen(type) {
      this.modalType = type;
      this.featuresComponent = !this.featuresComponent;
      if (this.featuresComponent) {
        this.tagging(type, type, "summary");
      }
    },
    tagging(type, buttonText, location) {
      var analytics = {
        language: this.$store.state.Pages.language,
        model: this.$store.state.Cars.cars.model.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: type,
        buttonText: buttonText,
        location: location,
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },
  components: {
    Features,
  },
};
</script>

<style scoped>
.featureView {
  position: absolute;
  top: 0 !important;
}
.utility {
  height: 2.6em;
  line-height: 2.6;
}
.utility button {
  margin-right: 9px;
  color: #666;
  letter-spacing: 1.7px;
  font-weight: 600;
  font-size: 11.5px;
}
.utility button:last-child {
  margin: 0;
}
.utility button::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15.1px;
  display: none;
}
.share::after {
  content: "\f1e0";
}
.save::after {
  content: "\f0c7";
}
.redirect-link {
  cursor: pointer;
}
table {
  width: 100%;
}
.printTable .head {
  font-size: 20px;
}
table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
tr td {
  display: flex;
}
#printMe {
  width: 100%;
  font-size: 50px;
  font-family: url("http://localhost:8080/src/assets/fonts/NissanBrand-Regular.ttf");
}
.price {
  font-family: url("http://localhost:8080/src/assets/fonts/NissanBrand-Regular.ttf");
}
.previewWrap {
  width: calc(100% - 278px);
}
.summaryPreview {
  transition: width 0.3s ease;
}
.toggleUp.active,
.toggleDown.active {
  color: #fff;
  background-color: #c3002f;
}
.summaryWrap {
  position: relative;
  /* overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; */
}
.forInterior {
  min-height: 490px;
  transition: 0.3s;
  overflow: hidden;
}
.forExterior {
  transition: 0.3s;
  overflow: hidden;
  min-height: 240px;
}
.sidePanel {
  /* position: sticky; */
  position: fixed;
  padding-top: 21px;
  height: 100vh;
  top: 105px;
  right: 15px;
  flex-flow: column nowrap;
  width: 278px;
  margin-left: 1.07143em;
}
.toggleContainer {
  position: absolute;
  height: 90px;
  padding: 0.35714em 5px;
  margin: 0.71429em 10px;
  border-radius: 10px;
  z-index: 2;
  cursor: pointer;
  border: 1px solid #eee;
  background-color: #f6f6f6;
  top: 0;
}
#printMe {
  display: none;
}
@media screen and (max-width: 60em) {
  .utility button span {
    display: none;
  }
  .utility button::after {
    display: block;
  }
  .utility button {
    margin-right: 5px;
    padding: 0.85714em 9px;
  }
  .utility button:last-child {
    margin-right: 9px;
  }
}
</style>
