<template>
  <div>
    <div
      class="w-full px-4 py-4"
      :style="language != 'ar' ? '' : 'padding-right:40px'"
      v-if="getallCars"
    >
      <TopHead v-bind:name="getallCars.model" />
      <div v-if="sortType == 'htl'">
        <CarVersion
          v-bind:key="index"
          v-for="(car, index) in getters(getallCars)"
          v-bind:car="car"
          v-bind:carid="index"
          v-bind:name="getallCars.model"
        />
      </div>
      <div v-else>
        <CarVersion
          v-bind:key="index"
          v-for="(car, index) in getallCars.grades"
          v-bind:car="car"
          v-bind:carid="index"
          v-bind:name="getallCars.model"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TopHead from "../version/topHead";
import CarVersion from "../version/cars";
export default {
  data() {
    return {
      selectedModel: "",
      sort_type: "",
    };
  },

  components: {
    TopHead,
    CarVersion,
  },
  methods: {
    tagging(type, buttonText, location) {
      var model = this.$store.getters.getallCars.model;
      var analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: type,
        buttonText: buttonText,
        location: location,
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
    getters(data) {
      if (data.grades) {
        let items = Object.keys(data.grades).reverse();
        var array = {};
        items.forEach((prop) => {
          array[prop] = data.grades[prop];
        });
        return array;
      } else {
        return;
      }

      //  console.log('dddddd',data.grades['2.0L_SL_Turbo'])
    },
    getCarName() {
      let uriParams = document.location.href.split("#");
      let uriParam = uriParams[1];
      if (!uriParam) return "";
      uriParams = uriParam.split("/");
      this.selectedModel = uriParams[0] ? uriParams[0] : "";
      //  console.log( this.selectedModel, "--")
    },
  },

  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    ...mapGetters(["getallCars"]),
    sortType() {
      return this.$store.getters.getSort;
    },

    //  ...mapGetters(['getGrade']),
  },

  created() {
    setTimeout(() => {
      if (this.$store.state.Pages.currentPage == "0") {
        this.tagging("load", "direct", "direct");
      }
    }, 3000);

    //this.getCarName()
    this.$store.dispatch("fetchCars");
    //this.$store.dispatch('getSelectedCar',this.selectedModel)
  },
};
</script>

<style></style>
