<template>
  <div
    class="flex md-custom-min:flex-row md-custom:flex-col-reverse flex-wrap overflow-hidden w-full  md-custom:pb-10 custom"
    :style="{ height: filterSectionHeight }"
  >
    <section
      class="flex flex-col md-custom:pb-1 wheelLeft"
      v-show="wheelComponent"
      :wheelIsOpen="wheelComponent"
    >
      <div
        class="relative overflow-x-hidden 
          md-custom-min:overflow-y-scroll p-5 w-full 
          innerLeft customClass"
        :style="{ height: filterSectionHeight }"
      >
        <h2 class="text-lg font-semibold">{{ $t("TABS.WHEELS") }}</h2>
        <p class="head font-semibold uppercase mt-2">
          <span
            class="font-light"
            :class="[language == 'en'] ? ' mr-1' : ' ml-1'"
          >
            {{ $t("CURRENT_SELECTION") }} :
          </span>
          <span v-if="language == 'en'"> {{ selectedWheelName.name }}</span>
          <span v-else> {{ selectedWheelName.name_ar }}</span>
        </p>

        <div class="mt-10 option">
          <ul>
            <li
              class="w-full mt-5"
              v-bind:key="index"
              v-for="(car, index) in wheelsLoop(getallCars.grades)"
              v-bind:car="car"
              v-bind:carid="index"
              v-bind:name="getallCars.model"
              :class="getWeelname.name == car.wheel.name ? 'active' : ''"
            >
              <div class="p-4">
                <h2
                  class="text-lg font-semibold uppercase"
                  v-if="language == 'en'"
                >
                  {{ car.wheel.name }}
                </h2>
                <h2 class="text-lg font-semibold uppercase" v-else>
                  {{ car.wheel.name_ar }}
                </h2>
                <div class="mt-4 price">
                  <span class="text-xs font-medium block">{{
                    $t("STARTING_FROM")
                  }}</span>
                  <span class="text-2xl font-light uppercase flex">
                    <span :class="[language == 'en' ? 'order-1' : 'order-2']">
                      {{ $t("CURRENCY") }}</span
                    >
                    <span :class="[language == 'en' ? 'order-2' : 'order-1']">
                      {{ Number(car.price).toLocaleString() }}</span
                    >
                  </span>
                </div>
              </div>
              <div
                v-if="getWeelname.name == car.wheel.name"
                class="current flex items-center p-3 text-herored border-0 border-t border-solid border-red text-xs uppercase font-semibold"
              >
                {{ $t("YOUR_CURRENT_SELECTION") }}
              </div>
              <div class="px-4">
                <!-- {{ getWeelname }} -->
                <button
                  v-if="getWeelname.name != car.wheel.name"
                  class="w-full text-left text-sm font-normal bg-herored text-white relative confirm  confirmed"
                  @click="showChoiceBox(index, { behavior: 'smooth' })"
                  v-bind:class="[
                    { deActive: isDeactive },
                    language != 'ar' ? 'confirm_en' : 'confirm_ar',
                  ]"
                >
                  {{ $t("CONFIRM") }}
                </button>
              </div>
              <!-- {{ index }} -->
              <ChoiceBox
                v-if="getWeelname.name != car.wheel.name && isChoiceBox[index]"
                v-bind:car="car"
                v-bind:selectedcar="getGradObj"
                v-on:choiceTriggered="closeChoice()"
                v-bind:name="getallCars.model"
              />
            </li>
          </ul>
        </div>
      </div>
      <Features v-if="featuresComponent" :featuresIsOpen="featuresComponent" />

      <div class="flex w-full fixed nextBtn">
        <button
          @click="changePage(4)"
          class="w-full h-full relative bg-herored text-white text-left text-sm uppercase"
        >
          <div class="text-white comingTabHead">
            {{ $t("NEXT") }} :
            <span
              class="text-white  font-semibold"
              :class="[language == 'en' ? 'ml-1' : 'mr-1']"
            >
              {{ $t("TABS.CAR_SUMMARY") }}</span
            >
          </div>
          <i
            class="text-white"
            :class="[
              language != 'ar' ? 'fas fa-angle-right ' : 'fas fa-angle-left ',
            ]"
            :style="language != 'ar' ? '  right: 21px;' : 'left:21px'"
          ></i>
        </button>
      </div>
    </section>

    <section class="flex-auto relative bg-white md-custom:hidden">
      <div class="flex-auto w-full h-full previewWrap">
        <div class="absolute inset-0 w-full h-full cursor-grab">
          <div class="In_Ex_View h-full" v-if="Object.keys(getGradObj).length">
            <transition name="fade">
              <PreviewBox
                v-bind:grades="getGradObj"
                v-bind:selectedColor="selectedExtColor"
                v-if="interior && !IsLoading"
              />
              <div class="fa-3x loader" v-if="IsLoading">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </transition>

            <Interior
              :page="'wheels'"
              v-bind:grades="getGradObj"
              v-bind:selectedIntColor="selectedIntColor"
              v-if="exterior"
            />
          </div>

          <div
            class="toggleBttns"
            :style="language != 'ar' ? 'right: 0;left: auto;' : 'right: auto;'"
          >
            <ViewSwitch @click.native="toggleView()" />

            <InExView @click.native="swapComponent" />
          </div>
          <Popover v-bind:grades="getGradObj" />
        </div>
      </div>
    </section>

    <section class="flex  md-custom-min:hidden md-custom:flex">
      <div class="w-2/4 wrapSec">
        <div class="inner">
          <NamePrice />
        </div>
      </div>
      <div class="w-2/4 wrapSec">
        <div class="inner">
          <ImageThumbnail v-on:popupClicked="popupOpen()" />
        </div>
      </div>

      <VehiclePopup v-if="vehiclePopup" v-on:popupClosed="popupClose()" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreviewBox from "../colors/360";
import Interior from "../colors/interior";
import ViewSwitch from "../../../components/toggleButtons/toggleView";
import InExView from "../../../components/toggleButtons/in_exView";
import Popover from "../../../components/pricePopover/detail";
import Features from "../../../components/sidePanel/panelView";
import ChoiceBox from "./choiceBox";
import NamePrice from "../../../components/smallScreen360-carDetails/namePrice";
import ImageThumbnail from "../../../components/smallScreen360-carDetails/imageThumbnail";
import VehiclePopup from "../../../components/smallScreen360-carDetails/vechiclePopup";

export default {
  props: [],
  data() {
    return {
      wheelComponent: true,
      interior: true,
      exterior: false,
      featuresComponent: false,
      vehiclePopup: false,
      isChoiceBox: [false, false, false, false, false, false, false],
      isDeactive: false,

      all_wheel: [],
    };
  },

  methods: {
    // ...mapActions(["changePage"]),
    changePage: function(value) {
      this.$store.dispatch("changePage", value);
      var analytics = {
        language: this.$store.state.Pages.language,
        model: this.$store.state.Cars.cars.model.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: "complete",
        buttonText: "car summary",
        location: "cta button",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },

    wheelsLoop: function(object) {
      function findObjectByKey(array, value) {
        for (var i = 0; i < array.length; i++) {
          if (array[i].wheel.name === value) {
            return array[i];
          }
        }
        return null;
      }

      let arr = [];

      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const element = object[key];
          // const element1 = object[key];
          var objWheel = {};

          if (element.wheel[0] != undefined) {
            if (!findObjectByKey(arr, element.wheel[0].name)) {
              objWheel.key = element.wheel[0].key;
              objWheel.wheel = element.wheel[0];
              objWheel.price = element.price;
              objWheel.Version_Label = element.Version_Label;
              arr.push(objWheel);
            }
          }

          var objWheel1 = {};

          if (element.wheel[1] != undefined) {
            // console.log(element.wheel[0], "---*****---");
            // console.log(element.wheel[1], "----------------");
            if (!findObjectByKey(arr, element.wheel[1].name)) {
              objWheel1.key = element.wheel[1].key;
              objWheel1.wheel = element.wheel[1];
              objWheel1.price = element.price;
              objWheel1.Version_Label = element.Version_Label;
              arr.push(objWheel1);
            }
          }
          console.log(objWheel, "-***************");

          // if (element1.wheel[1] != undefined) {
          //   if (!findObjectByKey(arr, element1.wheel[1].name)) {
          //     console.log(element1.wheel, "-----------");

          //     // setTimeout(function() {
          //     element1.wheel.reverse();
          //     console.log(element1.wheel, "-----------");
          //     arr.push(element1);
          //     // }, 2000);
          //   }
          // }

          // if (element.wheel[1] != undefined) {
          //   element1.wheel.shift();

          //   console.log(element1.wheel, "-----");

          //   if (element1.wheel[0] != undefined) {
          //     if (!findObjectByKey(arr, element1.wheel[0].name))
          //       arr.push(element1);
          //   }
          // }
        }
      }

      return arr.sort(function(a, b) {
        return a.key - b.key;
      });

      // if(arr.indexOf(val.name) === -1) {
      //   arr.push(val.name);
      //   return val.name;
      // }
    },
    toggleView() {
      this.wheelComponent = !this.wheelComponent;
    },

    swapComponent() {
      if (this.interior == true) {
        this.interior = false;
        this.exterior = true;
      } else {
        this.interior = true;
        this.exterior = false;
      }
    },
    changeDrawer() {
      this.featuresComponent = !this.featuresComponent;
    },
    popupOpen() {
      this.vehiclePopup = !this.vehiclePopup;
    },
    popupClose() {
      this.vehiclePopup = !this.vehiclePopup;
    },
    showChoiceBox(index) {
      if (this.isChoiceBox[index] == false)
        this.isChoiceBox[index] = !this.isChoiceBox[index];
      this.isDeactive = true;
      setTimeout(() => {
        const elmnt = this.$el.querySelector(".optionChoices");
        //  console.log(elmnt);
        elmnt.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    closeChoice() {
      this.isChoiceBox = [false, false, false, false, false, false, false];
      this.isDeactive = false;
      const elmnt = this.$el.querySelector(".customClass");
      elmnt.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
    filterSectionHeights() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!isMobile) {
        var width = document.body.clientWidth;
        return width / 1.77777777778 / 1.7 + "px";
      } else {
        return "100%";
      }
    },
  },
  computed: {
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
    filterSectionHeight() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!isMobile) {
        var width = document.body.clientWidth;
        return width / 1.77777777778 / 1.7 + "px";
      } else {
        return "100%";
      }
    },
    language() {
      return this.$store.state.Pages.language;
    },
    ...mapGetters(["getallCars"]),
    ...mapGetters(["getGradObj"]),

    ...mapGetters(["changeAlreadySelected"]),

    getWeelname() {
      return this.$store.state.Pages.selectedWheelName;
    },

    IsLoading() {
      return this.$store.getters.isLoading;
    },

    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    selectedExtColorName() {
      return this.$store.getters.selectedColors;
    },
    selectedWheelName() {
      return this.$store.state.Pages.selectedWheelName;
    },
  },

  created() {
    this.interior = true;
    window.addEventListener("resize", this.filterSectionHeights);
  },
  destroyed() {
    window.removeEventListener("resize", this.filterSectionHeights);
  },

  components: {
    PreviewBox,
    Interior,
    ViewSwitch,
    InExView,
    Popover,
    Features,
    ChoiceBox,
    NamePrice,
    ImageThumbnail,
    VehiclePopup,
  },
};
</script>

<style scoped>
.innerLeft {
  position: relative;
  padding-bottom: 161px;
  top: 0;
}
.wheelLeft {
  flex: 0 0 385px;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.head {
  font-size: 0.95em;
}
li {
  border: 1px solid #c1c1c1;
}
.active {
  border-width: 3px;
  border-color: #c3002f;
}
.deActive {
  background-color: #eee;
  border-color: #eee;
  color: #c1c1c1;
}
.current::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  font-size: 15px;
  margin-right: 7px;
}
li button {
  padding: 0.95em 20px;
}
.confirm_ar::after,
.change::after {
  left: 21px;
}
.confirm_en::after,
.change::after {
  right: 21px;
}
.confirm::after,
.change::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f05a";
  font-size: 14px;

  position: absolute;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.change::after {
  top: 39%;
  font-size: 17px;
}
.change::before {
  height: 0;
  width: 0;
  border-color: transparent transparent #252525;
  border-style: solid;
  border-width: 8px;
  position: absolute;
  top: -16px;
  left: 50%;
  content: "";
  margin-left: -8px;
}
.text-xxs {
  font-size: 0.75em;
}
.wrapSec {
  border-bottom: 1px solid #c1c1c1;
  background: #fff;
}
.nextBtn {
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 385px;
  height: 43.97px;
  width: 100%;
  z-index: 1;
}

button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.85714em 20px;
}
button > div {
  display: flex;
}
button i {
  position: absolute;

  font-size: 18px;
}
@media (max-width: 960px) {
  .nextBtn {
    max-width: 100%;
  }
}
</style>
