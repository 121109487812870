<template>
  <div class="">
    <VPannellum
      class="pannellum"
      :src="selectedIntColor.image_url"
      :show-zoom="false"
      :yaw.sync="yaw"
      :pitch.sync="pitch"
      :hfov.sync="hfov"
      :auto-load="true"
    >
    </VPannellum>
  </div>
</template>
<script>
import equirectangularUrl from "../../../assets/img/white-bg.jpg";

export default {
  props: ["selectedIntColor"],
  components: {},
  data() {
    return {
      url: equirectangularUrl,
      yaw: 0, // postioning
      hfov: 120,
      pitch: -20,
    };
  },
};
</script>

<style>
p {
  color: #fff;
}
.pannellum.pnlm-container {
  position: fixed !important;
}
.controls {
  display: none;
}
.pnlm-load-box {
  z-index: -999;
  opacity: 0;
}
.pnlm-render-container .preview-img {
  display: none;
}
.pnlm-container {
  background: #fff !important;
}
</style>
