<template>
  <div
    class="featureBtn flex items-center text-sm cursor-pointer text-lightBlack"
    v-on:click="featuresOpen()"
  >
    {{ $t("ALLFEATURES") }}
    <span class="ml-2"
      ><i
        class="fas  text-base text-herored"
        :class="[language != 'ar' ? 'fa-angle-right' : 'fa-angle-left']"
      ></i
    ></span>
  </div>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
  },
  methods: {
    featuresOpen() {
      this.$emit("featuresClicked");
    },
  },
};
</script>

<style></style>
