<template>
  <button
    v-if="grade.spot"
    @click="selectExt(grade), tag()"
    :class="selectedColorName.name == grade.name ? 'select-color' : ''"
  >
    <div class="absolute inset-0 w-full h-full">
      <img
        :src="grade.spot"
        class="absolute inset-0 w-screen custom-h"
        alt=""
      />
      <div class="itemHeader"></div>
    </div>
  </button>
</template>

<script>
export default {
  props: ["grade", "extPath", "selectedColorName"],
  methods: {
    selectExt(grade) {
      const data = {
        key: grade.key,
        name: grade.name,
        name_ar: grade.name_ar,
        spot: grade.spot,
        ext_path: this.extPath,
      };

      this.$store.dispatch("setSelectedColor", data);
    },
    tag() {
      var model = this.$store.getters.getallCars.model;
      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        item_slection: this.$store.state.Pages.selectedExtColors.name,
        type: "selection",
        buttonText: "Confirm",
        location: "cta",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },
};
</script>

<style scoped>
.custom-h {
  height: 100%;
}
button {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 4px;
  transition-property: box-shadow, -webkit-transform;
  transition-property: transform, box-shadow;
  transition-duration: 0.3s;
  pointer-events: all;
  outline: none;
  box-shadow: 0 15px 8px -10px transparent;
}
button:focus,
button:hover {
  outline: none;
  box-shadow: 0 15px 8px -10px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
}
button:before {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  width: 100%;
  height: 100%;
  content: "";
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
  z-index: 1;
}
.itemHeader {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.select-color .itemHeader {
  opacity: 1;
}
.select-color::before {
  border-color: #c3002f;
  border-width: 2px;
}
.itemHeader:before {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 43px 43px 0;
  -webkit-transition: border 0.3s;
  transition: border 0.3s;
  border-right-color: #c3002f;
}
.itemHeader h2 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.select-color:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 6px;
  right: 7px;
  left: auto;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  color: #fff;
}
</style>
