<template>
  <div class="toggleBtn">
    <button class="toggleView" @click="toggleView, tag()">
      <div class="btnContent" v-if="colorsIsOpen">
        <div class="wrapInner">
          <span class="txt view"> {{ $t("VIEW") }} </span>
          <i class="fas fa-expand-alt"></i>
        </div>
      </div>
      <div class="btnContent" v-else>
        <div class="wrapInner">
          <span class="txt hide">{{ $t("HIDE") }}</span>
          <i class="fas fa-times"></i>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: [""],
  data() {
    return {
      colorsIsOpen: true,
    };
  },

  methods: {
    tag() {
      var model = this.$store.getters.getallCars.model;

      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: "in_ext",
        buttonText: "exterior view",
        location: "exterior view",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
    toggleView() {
      this.colorsIsOpen = !this.colorsIsOpen;
    },
  },

  created() {},
};
</script>

<style scoped>
.toggleBtn {
  width: 100%;
  display: block;
  position: relative;
  height: 100%;
}
.btnContent {
  transition: width 0.4s ease-in-out;
  position: absolute;
  display: block;
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  right: 1px;
  top: 15px;
  cursor: pointer;
}
.btnContent i {
  position: absolute;
  margin: auto;
  font-size: 19px;
  color: #000;
  right: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt {
  top: 0;
  left: -19px;
  right: 0;
  padding: 0.75rem 0;
  color: #333;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  bottom: 0;
  position: relative;
}
.toggleView:hover .btnContent {
  width: 6.88em;
}
.toggleView:hover .txt {
  opacity: 1;
}
.wrapInner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
}
</style>
