<template>
  <div class="optionChoices">
    <!-- <span class="arrow"></span> -->
    <div class="choicesInner">
      <h2 class="text-base font-semibold uppercase text-white">
        {{ $t("REMOVE_SELECTION") }}
      </h2>
      <ul class="mt-4">
        <li class="w-full flex justify-between mb-1">
          <span class="text-sm  text-white" v-if="language == 'en'"
            >{{ selectedcar.wheel[0].name }}
          </span>
          <span class="text-sm  text-white" v-else
            >{{ selectedcar.wheel[0].name_ar }}
          </span>
          <span class="text-sm  text-white"></span>
        </li>
        <li class="w-full flex justify-between mb-1">
          <span class="text-sm  text-white">{{
            selectedcar.Version_Label
          }}</span>
          <span class="text-sm  text-white flex">
            <span
              :class="[
                language == 'en' ? 'text-white order-1' : 'text-white order-2',
              ]"
              >{{ $t("CURRENCY") }}</span
            >
            <span
              :class="[
                language == 'en' ? 'text-white order-2' : 'text-white order-1',
              ]"
              >{{ Number(selectedcar.price).toLocaleString() }}
            </span>
          </span>
        </li>
      </ul>
    </div>

    <div class="mt-4 choicesInner">
      <h2 class="text-base font-semibold uppercase text-white">
        {{ $t("REPLACE_SELECTION") }}
      </h2>
      <ul class="mt-4">
        <li class="w-full flex justify-between mb-1">
          <span class="text-sm  text-white" v-if="language == 'en'">{{
            car.wheel.name
          }}</span>
          <span class="text-sm  text-white" v-else>{{
            car.wheel.name_ar
          }}</span>
          <span class="text-sm  text-white"></span>
        </li>
        <li class="w-full flex justify-between mb-1">
          <span class="text-sm  text-white">{{ car.Version_Label }}</span>
          <span class="text-sm flex  text-white">
            <span
              :class="[
                language == 'en' ? 'text-white order-1' : 'text-white order-2',
              ]"
              >{{ $t("CURRENCY") }}</span
            >
            <span
              :class="[
                language == 'en' ? 'text-white order-2' : 'text-white order-1',
              ]"
              >{{ Number(car.price).toLocaleString() }}
            </span>
          </span>
        </li>
      </ul>
    </div>
    <div class="mt-4 choicesInner">
      <h2
        class="flex justify-between text-base font-semibold uppercase pb-4 border-0 border-bw-1 border-solid border-gray-400"
      >
        <span class="text-white">{{ $t("NEW_TOTAL") }}</span>
        <i class="text-white flex">
          <span
            :class="[
              language == 'en' ? 'text-white order-1' : 'text-white order-2',
            ]"
            >{{ $t("CURRENCY") }}</span
          >
          <span
            :class="[
              language == 'en' ? 'text-white order-2' : 'text-white order-1',
            ]"
            >{{ Number(car.price).toLocaleString() }}
          </span>
        </i>
      </h2>
      <div class="flex justify-between mt-4 btnWrap">
        <button
          class="w-full text-sm font-normal uppercase   relative cancel"
          :class="[language != 'ar' ? 'mr-4' : 'ml-4']"
          @click="CloseChoiceBox()"
        >
          <span> {{ $t("CANCEL") }}</span>
          <i
            class="absolute  text-base"
            :style="language != 'ar' ? 'right: 22px;' : 'left:22px;right:0px'"
            :class="[
              language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left',
            ]"
          ></i>
        </button>
        <button
          class="w-full text-sm font-normal uppercase relative   bg-herored text-white"
          @click="confirm(car)"
        >
          <span class="text-white">{{ $t("CONFIRM") }}</span>
          <i
            class="text-white absolute  text-base"
            :class="[
              language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left',
            ]"
            :style="language != 'ar' ? 'right: 22px;' : 'left:22px;'"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["car", "selectedcar", "name"],
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
  },
  methods: {
    confirm(car) {
      console.log("confirm");
      const grade = car.Version_Label.replace(/ /g, "_");
      this.$store.commit("setSelectedWheel", car.wheel);
      this.$store.dispatch("SelectedObjWheel", [grade, car.wheel.key]);
      this.$emit("choiceTriggered");
      var model = this.$store.getters.getallCars.model;
      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        item_slection: this.$store.state.Pages.selectedWheelName.name,
        type: "selection",
        buttonText: "wheels",
        location: "selection",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },

    CloseChoiceBox() {
      this.$emit("choiceTriggered");
    },
  },
};
</script>

<style scoped>
.optionChoices {
  position: relative;
  padding: 1.07143em 15px;
  background: #252525;
  width: 355px;
  margin-top: 15px;
}
.arrow {
  position: absolute;
  top: -6px;
  left: 79px;
  right: auto;
  display: block;
  width: 12px;
  height: 12px;
  margin: auto;
  content: "";
  background-color: inherit;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
li {
  padding: 0.78571em 10px;
  background-color: #343434;
}
.border-bw-1 {
  border-bottom-width: 1px;
}
.border-gray-400 {
  border-color: #8a8a8a;
}
.cancel {
  color: #343434;
  border: 1px solid #eee;
  background: #eee;
}
.btnWrap button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.95em 20px;
}
.btnWrap button i {
  top: 50%;
  transform: translate(-50%, -50%);
}
.cancel i {
  color: #c3002f;
}
.optionChoices {
  width: 100%;
}
</style>
