var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popoverWrap",style:(_vm.language != 'ar' ? 'right: 15px; left: auto;' : 'left: 15px; right: auto;')},[_c('div',{staticClass:"inner"},[_c('button',{staticClass:"togglePop",class:[
        _vm.language != 'ar' ? 'togglePop_en' : 'togglePop_ar',
        _vm.isActive ? 'open' : 'close' ],on:{"click":function($event){return _vm.togglePop()}}}),(_vm.popIsOpen)?_c('div',{staticClass:"detailWrap"},[_c('section',{class:[_vm.language == 'en' ? 'section_en' : 'section_ar']},[(_vm.getallCars.model && _vm.language != 'ar')?_c('h2',{staticClass:"text-base font-semibold uppercase"},[_vm._v(" "+_vm._s(_vm.getallCars.model.model)+" ")]):(_vm.getallCars.model && _vm.language == 'ar')?_c('h2',{staticClass:"text-base font-semibold uppercase"},[_vm._v(" "+_vm._s(_vm.getallCars.model.model_ar)+" ")]):_vm._e(),(_vm.grades && _vm.grades.technical_specs && _vm.language != 'ar')?_c('h4',{staticClass:"text-sm font-normal py-1"},[_vm._v(" "+_vm._s(_vm.grades.Version_Label.replace("4.0L V6 ", "").replace( "5.6L V8 400HP ", "" ))+" ")]):_vm._e(),(_vm.grades && _vm.grades.technical_specs && _vm.language == 'ar')?_c('h4',{staticClass:"text-sm font-normal  py-1"},[_vm._v(" "+_vm._s(_vm.grades.Version_Label.replace("4.0L V6 ", "").replace( "5.6L V8 400HP ", "" ))+" ")]):_vm._e(),_c('div',{class:[_vm.language == 'en' ? 'cst_btn_wrap_en' : 'cst_btn_wrap_ar']},[(_vm.getallCars.model)?_c('a',{staticClass:"uppercase outerLink",class:[_vm.language == 'en' ? 'outerLink_en' : 'outerLink_ar'],attrs:{"target":"_self","href":_vm.language == 'en'
                ? _vm.parent_url +
                  'book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
                  _vm.codes[_vm.getallCars.model.model.toLowerCase()]
                : _vm.parent_url +
                  'book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
                  _vm.codes[_vm.getallCars.model.model.toLowerCase()]}},[_c('span',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.$t("TEST_DRIVE")))])]):_vm._e()])]),_c('section',{class:[_vm.language == 'en' ? 'section_en' : 'section_ar']},[_c('div',{staticClass:"text-xs font-normal priceIn"},[_vm._v(" "+_vm._s(_vm.$t("TOTAL_PRICE"))+" * ")]),(_vm.grades)?_c('span',{staticClass:"block text-xl font-light mt-2 flex"},[_c('span',{class:[_vm.language == 'en' ? 'order-1 px-1' : 'order-2 px-1']},[_vm._v(" "+_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[_vm.language == 'en' ? 'order-2' : 'order-1']},[_vm._v(" "+_vm._s(Number(_vm.grades.price).toLocaleString())+" ")])]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }