<template>
  <div class="w-full">
    <ul>
      <li v-for="(grade, index) in grades.interior" :key="index">
        <ClothOption
          :selectedIntColor="selectedIntColor"
          :grade="grade"
          :index="index"
          :allCars="allCars"
          :type="'model_interior'"
        />
      </li>
    </ul>
    <div class="border"></div>
    <ul>
      <li v-for="(grade, index) in all_interior" :key="index">
        <ClothOption
          :selectedIntColor="selectedIntColor"
          :index="index"
          :allCars="allCars"
          :grade="grade"
          :type="'all_interior'"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import ClothOption from "./clothOption";
export default {
  props: ["selectedIntColor", "grades", "allCars"],

  data() {
    return {
      model_interior: [],
      all_interior: [],
    };
  },
  watch: {
    grades: function(newVal) {
      var arr = [];

      newVal.interior.forEach((element) => {
        arr = [...arr, element.name];
      });
      this.model_interior = arr;

      var temp_array = [];
      var interior_array = [];
      for (var key in this.allCars.grades) {
        const interiror = this.allCars.grades[key].interior;

        interiror.forEach((prop) => {
          var is_exist = this.model_interior.includes(prop.name);
          if (is_exist == false) {
            const tmp = temp_array.includes(prop.name);
            if (tmp == false) {
              temp_array = [...temp_array, prop.name];
              interior_array = [...interior_array, prop];
            }
          }
        });
      }

      this.all_interior = interior_array;
    },
  },

  components: {
    ClothOption,
  },
  methods: {},
  computed: {},
  mounted() {
    //  if(this.grades){
    //  this.array = this.grades;
    //  }
    // for (const key of this.array) {
    //       console.log('keys',key)
    //     }
  },
};
</script>

<style scoped>
.border {
  border-bottom: 1px solid #faeeee;
}
ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  grid-gap: 15px;
  padding-bottom: 15px !important;
  margin-top: 11px;
}
li {
  width: 100%;
  display: inline-block;
}
@media (max-width: 960px) {
  ul {
    grid-template-columns: repeat(auto-fill, minmax(32%, 1fr));
  }
  .optionChoices {
    width: 100%;
  }
}
</style>
