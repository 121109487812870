import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/tailwind.css";
import VueThreeSixty from "./components/360/360Base.vue";
import { VueHammer } from "vue2-hammer";
import VuePannellum from "./components/panorama/vue-pannellum.vue";
import VTooltip from "v-tooltip";

import store from "./store";
import i18n from "./i18n";
import helpers from "../src/analytics";

const generalHelpers = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};
Vue.use(generalHelpers);

Vue.use(VueHammer);
Vue.component("vue-three-sixty", VueThreeSixty);
Vue.component("VPannellum", VuePannellum);
Vue.use(VTooltip);

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let language = to.name;
  if (!language) {
    language = "en";
  }

  store.state.Pages.language = language;
  i18n.locale = language;

  if (language === "ar") {
    setTimeout(() => {
      document.body.classList.add("rtl");
    }, 500);
  }
  next();
});

new Vue({
  render: (h) => h(App),
  router,
  i18n,
  store,
}).$mount("#app");
