<template>
  <div class="contentIn pt-3 md-custom:items-center md-custom:flex">
    <div class="mb-3 heroHead">
      <h2 class="uppercase" v-if="language == 'en'">{{ name.model }}</h2>
      <h2 class="uppercase" v-else>{{ name.model_ar }}</h2>
      <h3>
        {{
          car.Version_Label.replace("4.0L V6 ", "").replace(
            "5.6L V8 400HP ",
            ""
          )
        }}
      </h3>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["car", "name"],
  data() {
    return {
      model: "",
    };
  },

  computed: {
    ...mapState({
      cartitle: (state) => state.cartitle,
      language() {
        return this.$store.state.Pages.language;
      },
    }),
  },

  created() {},
};
</script>

<style scoped>
.heroHead h2 {
  font-size: 1.1em;
  font-weight: 300;
}
.heroHead h3 {
  font-weight: 600;
  font-size: 1.81em;
  margin-top: 2px;
  margin-bottom: 0.35714em;
}
@media (max-width: 668px) {
  .heroHead h2 {
    font-size: 0.95em;
  }
  .heroHead h3 {
    font-size: 1.1em;
  }
}
</style>
