<template>
  <div class="accordion">
    <div class="header" v-on:click="toggle">
      <span
        name="header"
        class="text-base font-semibold"
        v-if="language == 'en'"
        >{{ title.name }}</span
      >

      <span name="header" class="text-base font-semibold" v-else>{{
        title.name_ar
      }}</span>
      <i
        class="fa fa-angle-down header-icon"
        v-bind:class="{ rotate: show }"
      ></i>
    </div>
    <transition
      name="accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div class="body" v-show="show">
        <div class="body-inner">
          <slot> </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["accordinIsOpen", "title"],
  data() {
    return {
      show: false,
      accordinComponent: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
  },
  methods: {
    toggle: function() {
      this.show = !this.show;
      this.accordinComponent = !this.show;
    },
    beforeEnter: function(el) {
      el.style.height = "0";
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function(el) {
      el.style.height = "0";
    },
  },
  created() {},
};
</script>

<style scoped>
.header {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  padding: 0.9375em 15px;
  font-size: 1.14286em;
  line-height: 1.2;
  text-transform: uppercase;
  border-top: 1px solid #d9d9d9;
}

.accordion .header-icon {
  transform: rotate(0deg);
  transition-duration: 0.3s;
  font-size: 22.3px;
  color: #c3002f;
}

.accordion .body {
  /*   display: none; */
  overflow: hidden;
  background-color: #fff;
  transition: 150ms ease-out;
}

.accordion .body-inner {
  padding: 3px;
  overflow-wrap: break-word;
}

.accordion .header-icon.rotate {
  transform: rotate(180deg);
  transition-duration: 0.3s;
}
ul li i {
  display: block;
  position: relative;
  border-radius: 50%;
  left: 0;
  top: 3px;
  width: 5px;
  height: 5px;
  background: #000;
}
</style>
