<template>
  <div
    class="optionChoices"
    :class="[language != 'ar' ? 'optionChoices_en' : 'optionChoices_ar']"
  >
    <span
      :class="[language != 'ar' ? 'arrow arrow_en' : 'arrow arrow_ar']"
    ></span>
    <div class="choicesInner">
      <h2 class="text-base font-semibold uppercase text-white">
        {{ $t("REMOVE_SELECTION") }}
      </h2>
      <ul class="mt-4">
        <li class="w-full flex justify-between mb-1">
          <span class="text-sm  text-white">{{
            allCars.grades[selectedIntColor.grades[0].replace(/ /g, "_")]
              .Version_Label
          }}</span>
          <span class="text-sm flex  text-white">
            <span
              :class="[
                language == 'en' ? 'text-white order-1' : 'text-white order-2',
              ]"
              >{{ $t("CURRENCY") }}</span
            >
            <span
              :class="[
                language == 'en' ? 'text-white order-2' : 'text-white order-1',
              ]"
              >{{
                Number(
                  allCars.grades[selectedIntColor.grades[0].replace(/ /g, "_")]
                    .price
                ).toLocaleString()
              }}
            </span>
          </span>
        </li>
      </ul>
    </div>

    <div class="mt-4 choicesInner">
      <h2 class="text-base font-semibold uppercase text-white">
        {{ $t("REPLACE_SELECTION") }}
      </h2>
      <ul class="mt-4">
        <li class="w-full flex justify-between mb-1">
          <span class="text-sm  text-white">
            {{
              allCars.grades[data.grades[0].replace(/ /g, "_")].Version_Label
            }}</span
          >
          <span class="text-sm flex  text-white">
            <span
              :class="[
                language == 'en' ? 'text-white order-1' : 'text-white order-2',
              ]"
              >{{ $t("CURRENCY") }}</span
            >
            <span
              :class="[
                language == 'en' ? 'text-white order-2' : 'text-white order-1',
              ]"
              >{{
                Number(
                  allCars.grades[data.grades[0].replace(/ /g, "_")].price
                ).toLocaleString()
              }}
            </span>
          </span>
        </li>
      </ul>
    </div>
    <div class="mt-4 choicesInner">
      <h2
        class="flex justify-between text-base font-semibold uppercase pb-4 border-0 border-bw-1 border-solid border-gray-400"
      >
        <span class="text-white">{{ $t("NEW_TOTAL") }}</span>
        <i class="text-white flex">
          <span
            :class="[
              language == 'en' ? 'text-white order-1' : 'text-white order-2',
            ]"
            >{{ $t("CURRENCY") }}</span
          >
          <span
            :class="[
              language == 'en' ? 'text-white order-2' : 'text-white order-1',
            ]"
            >{{
              Number(
                allCars.grades[data.grades[0].replace(/ /g, "_")].price
              ).toLocaleString()
            }}
          </span>
        </i>
      </h2>
      <div class="flex justify-between mt-4 btnWrap">
        <button
          @click="closeChoiceBox()"
          :class="[language != 'ar' ? 'mr-4' : 'ml-4']"
          class="w-full text-sm font-normal uppercase  relative cancel"
        >
          <span> {{ $t("CANCEL") }} </span>
          <i
            class="   absolute right-0 text-base"
            :class="[
              language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left',
            ]"
            :style="language != 'ar' ? 'right: 22px' : 'left: 22px'"
          />
        </button>

        <button
          class="w-full text-sm font-normal uppercase relative   bg-herored text-white"
          @click="confirm(data.grades[0].replace(/ /g, '_'))"
        >
          <i
            class="text-white absolute right-0 text-base"
            :style="language != 'ar' ? 'right: 22px;' : 'left: 22px; '"
            :class="[
              language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left',
            ]"
          ></i>
          <span class="text-white"> {{ $t("CONFIRM") }} </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "allCars", "selectedIntColor"],
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
  },
  methods: {
    confirm(grade) {
      this.$store.dispatch("SelectedObj", grade);
      var model = this.$store.getters.getallCars.model;
      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        item_slection: this.$store.state.Pages.selectedIntColors.name,
        type: "selection",
        buttonText: "upholstery",
        location: "selection",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
    closeChoiceBox() {
      this.$emit("triggeredcloseChoice");
    },
  },
};
</script>

<style scoped>
.optionChoices {
  position: relative;
  padding: 1.07143em 15px;
  background: #252525;
  margin-top: 15px;
  width: 345px;
}
.arrow {
  position: absolute;
  top: -6px;

  display: block;
  width: 12px;
  height: 12px;
  margin: auto;
  content: "";
  background-color: inherit;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow_en {
  left: 79px;
  right: auto;
}
ul li:nth-child(2n + 2) .arrow_en {
  right: 79px !important;
  left: auto !important;
}
.arrow_ar {
  right: 79px;
  left: auto;
}
li {
  padding: 0.78571em 10px;
  background-color: #343434;
}
.border-bw-1 {
  border-bottom-width: 1px;
}
.border-gray-400 {
  border-color: #8a8a8a;
}
.cancel {
  color: #343434;
  border: 1px solid #eee;
  background: #eee;
}
.btnWrap button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.95em 20px;
}
.btnWrap button i {
  top: 50%;
  transform: translate(-50%, -50%);
}
.cancel i {
  color: #c3002f;
}
ul li:nth-child(2n + 2) div.optionChoices_en {
  margin-left: calc(-100% - 15px);
  margin-right: calc(-100% + 15px);
}

ul li:nth-child(2n + 2) div.optionChoices_ar {
  margin-left: calc(-100% - -15px);
  margin-right: calc(-100% + -15px);
}
ul li:nth-child(2n + 2) div.optionChoices_ar .arrow_ar {
  left: 79px;
  right: auto;
}
@media (max-width: 960px) {
  .optionChoices {
    /* width: 100%; */
  }
  /* .optionChoices {
    position: absolute;
  } */
}
</style>
