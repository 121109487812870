<template>
  <div class="flex w-full fixed nextBtn">
    <button
      @click="changePage(2), tag()"
      class="w-full h-full relative bg-herored text-white text-left text-sm uppercase"
    >
      <div class="text-white comingTabHead">
        {{ $t("NEXT") }} :
        <span
          class="text-white  font-semibold"
          :class="language == 'en' ? 'ml-1' : 'mr-1'"
          >{{ $t("TABS.UPHOLSTERY") }}</span
        >
      </div>
      <i
        class="text-white"
        :class="[
          language != 'ar' ? 'fas fa-angle-right ' : 'fas fa-angle-left ',
        ]"
        :style="language != 'ar' ? '  right: 21px;' : 'left:21px'"
      ></i>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    ...mapGetters(["getallCars"]),
  },
  methods: {
    ...mapActions(["changePage"]),
    tag() {
      var analytics = {
        language: this.$store.state.Pages.language,
        model: this.$store.state.Cars.cars.model.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: "upholstery",
        buttonText: "upholstery",
        location: "cta",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },
};
</script>

<style scoped>
.nextBtn {
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 385px;
  height: 43.97px;
  width: 100%;
  z-index: 1;
}
button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.85714em 20px;
}
button > div {
  display: flex;
}
button i {
  position: absolute;

  font-size: 18px;
}
@media (max-width: 960px) {
  .nextBtn {
    max-width: 100%;
  }
}
</style>
