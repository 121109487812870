import { render, staticRenderFns } from "./namePrice.vue?vue&type=template&id=45dc251c&scoped=true&"
import script from "./namePrice.vue?vue&type=script&lang=js&"
export * from "./namePrice.vue?vue&type=script&lang=js&"
import style0 from "./namePrice.vue?vue&type=style&index=0&id=45dc251c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45dc251c",
  null
  
)

export default component.exports