<template>
  <div class="w-full">
    <span class="text-xs">Starting From</span>
    <div class="priceInn flex ">
      <span :class="[language == 'en' ? 'order-1' : 'order-2']">
        {{ $t("CURRENCY") }}
      </span>
      <span :class="[language == 'en' ? 'order-2' : 'order-1']">{{
        car.price
      }}</span>
      <span :class="[language == 'en' ? 'order-2' : 'order-3']">*</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["car", "name"],
  computed: {
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
    language() {
      return this.$store.state.Pages.language;
    },
  },
};
</script>

<style scoped>
.priceInn {
  font-size: 1.7em;
  line-height: 1;
  font-weight: 300;
  color: #000;
}
.textMuted {
  color: #5c5c5c;
  font-size: 0.71429em;
  line-height: 14px;
  margin-top: 0.77em;
}
</style>
