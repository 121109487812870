<template>
  <div>
    <div class="w-full text-xs font-normal mb-2">
      {{ $t("CURRENT_SELECTION") }}
    </div>
    <div
      class="flex justify-between font-semibold text-sm"
      v-if="selectedIntColor.name"
    >
      <span v-if="language != 'ar'">{{ selectedIntColor.name }}</span>
      <span v-else>{{ selectedIntColor.name_ar }}</span>

      <!-- <span>AED94,900</span> -->
    </div>

    <ClothOptions
      v-bind:selectedIntColor="selectedIntColor"
      v-bind:allCars="getallCars"
      v-bind:grades="getGradObj"
    />
  </div>
</template>

<script>
import ClothOptions from "./clothOptions";

import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["getallCars"]),
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
  },
  components: {
    ClothOptions,
  },
};
</script>

<style scoped>
.toggleBttns {
  padding: 1.07143em 15px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  width: 122px;
  height: 90px;
}
</style>
