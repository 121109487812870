// var url =
//   window.location != window.parent.location
//     ? document.referrer
//     : document.location.href;
// // var host = window.location.hostname;
// console.log("url", url);
// var referer = window.parent.location.href;
// referer.replace(".html", "");
window.dataLayer = window.dataLayer || [];

// var previous_page = referer.split("/");
// function getPreviousPage() {
//   var name = "";

//   if (previous_page.length == 4) {
//     return "Homepage";
//   } else if (previous_page.length == 4 && url.length == 2) {
//     return "Homepage";
//   } else if (previous_page.length <= 1 && url.length == 2) {
//     return "Homepage";
//   } else if (previous_page.length == 1 && url.length > 2) {
//     var filtered = url.filter((item) => item);
//     for (let index = 0; index < filtered.length; index++) {
//       if (index + 1 == filtered.length) {
//         switch (filtered[index]) {
//           case "shedrives":
//             return "she-drives";

//           case "fleet":
//             return "nissan-fleet";

//           default:
//             previous_page[index];
//             break;
//         }
//         name += filtered[index];

//         if (filtered[index] == "vehiles" && filtered.length == 2) {
//           name += "|overview";
//         }
//       } else {
//         name += filtered[index] + "|";
//       }
//     }
//     return name;
//   } else {
//     var myarray = previous_page.filter((item) => item);
//     for (let index = 2; index < myarray.length; index++) {
//       if (myarray[index] != "") {
//         console.log(myarray[index]);
//         if (index + 1 == myarray.length) {
//           switch (myarray[index]) {
//             case "shedrives":
//               return "she-drives";

//             case "fleet":
//               return "nissan-fleet";

//             default:
//               myarray[index];
//               break;
//           }

//           name += myarray[index];

//           //vevhiles
//           if (myarray[2] == "vehicles" && myarray.length == 4) {
//             name += "|overview";
//           }
//         } else {
//           if (myarray[index] != "") {
//             name += myarray[index] + "|";
//           }
//         }
//       }
//     }
//     return name;
//   }
// }

export default {
  tag(data) {
    console.log("currentpage", data);
    // var s;
    // var pages = ["Versions", "Colours", "Upholstery", "Wheels", "car summary"];
    // var vehicle_category = {
    //   "370Z": "SPORTS CARS",
    //   ALTIMA: "PASSENGER CARS",
    //   CIVILIAN: "COMMERCIAL VEHICLES",
    //   KICKS: "SUVS & CROSSOVERS",
    //   URVAN: "COMMERCIAL VEHICLES",
    //   MAXIMA: "PASSENGER CARS",
    //   NAVARA: "COMMERCIAL VEHICLES",
    //   PATHFINDER: "SUVS & CROSSOVERS",
    //   PATROL: "SUVS & CROSSOVERS",
    //   "PATROL-PICKUP": "COMMERCIAL VEHICLES",
    //   "PATROL-SAFARI": "SUVS & CROSSOVERS",
    //   SENTRA: "PASSENGER CARS",
    //   SUNNY: "PASSENGER CARS",

    //   "X-TRAIL": "SUVS & CROSSOVERS",
    //   "GT-R": "SPORTS CARS",
    //   MICRA: "PASSENGER CARS",
    //   LEAF: "PASSENGER CARS",
    //   "X-TERRA": "SUVS & CROSSOVERS",
    //   JUKE: "SUVS & CROSSOVERS",
    //   MAGNITE: "SUVS & CROSSOVERS",
    //   NP200: "COMMERCIAL VEHICLES",
    //   "NEW MICRA": "PASSENGER CARS",
    //   QASHQAI: "SUVS & CROSSOVERS",
    //   ALMERA: "PASSENGER CARS",
    //   NP300: "COMMERCIAL VEHICLES",
    // };

    // var vehicle_id = {
    //   "370Z": "Z34C",
    //   ALTIMA: "L34",
    //   CIVILIAN: "W41",
    //   KICKS: "P15",
    //   URVAN: "E26",
    //   MAXIMA: "A36",
    //   NAVARA: "D23",
    //   PATHFINDER: "R52",
    //   PATROL: "Y62",
    //   "PATROL-PICKUP": "UY61",
    //   "PATROL-SAFARI": "Y61",
    //   SENTRA: "B17",
    //   SUNNY: "N18",
    //   "X-TRAIL": "T32",
    //   "GT-R": "R35",
    //   MICRA: "K13",
    //   LEAF: "ZE1",
    //   "X-TERRA": "P60A",
    //   JUKE: "JUKE",
    //   MAGNITE: "EM2",
    //   NP200: "UA90",
    //   "NEW MICRA": "K14",
    //   QASHQAI: "J11",
    //   ALMERA: "LO2B",
    //   NP300: "D22",
    // };
    //var toolStart = false;
    // var toolStart = false;
    // s = window._satellite.getToolsByType("sc")[0].getS();
    // s.account = "nissansastaging"; //account
    // s.currencyCode = "SAR";
    const pageNumber = parseInt(data.currentpage) + 1;
    // var uppercaseModel = data.model.toString().toUpperCase();
    if (data.currentpage == "0" && data.type == "load") {
      //
    } else if (data.currentpage == 0 && data.type == "versions") {
      load(data);
    } else if (data.type == "selection") {
      // var toolStart = true;
      // const pNumber = parseInt(data.currentpage) + 1;

      if (data.currentpage == 0) {
        data.currentpage = 1;
        data.buttonText = "nav-bar";
        data.location = "nav-bar";
        load(data);
      }
    } else if (data.type == "sort" || data.type == "in_ext") {
      console.log("pageNumber", pageNumber);
    } else if (data.currentpage == "1" && data.type == "colours") {
      load(data);
    } else if (data.currentpage == "2" && data.type == "upholstery") {
      load(data);
    } else if (data.currentpage == "3" && data.type == "wheels") {
      load(data);
    } else if (data.currentpage == 4 && data.type == "complete") {
      //
    }
    function load(data) {
      const pN = parseInt(data.currentpage) + 1;
      console.log("pagenumber", pN);
    }
  },
};
