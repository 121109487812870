<template>
  <div class="w-full heroWrap">
    <div
      class="flex flex-wrap mt-4 heroItem"
      :class="[language == 'en' ? 'heroItem_en' : 'heroItem_ar']"
      v-if="car.wheel.length && car.interior.length"
    >
      <section class="flex md-custom-min:w-6/12 w-full px-4 my-3 inner">
        <figure class="hero">
          <div class="car-wrapper">
            <img
              :src="
                car.wheel[0].path +
                  '/' +
                  car.colors[
                    name.model !== 'Kicks'
                      ? Math.floor(
                          Math.random() * Math.floor(car.colors.length)
                        )
                      : Math.floor(
                          (Math.random() * Math.floor(car.colors.length)) / 2
                        )
                  ].key +
                  '/' +
                  '01.jpg'
              "
              class="w-full max-w-full"
              alt=""
            />
          </div>
          <!-- <div
            v-bind:style="{
              backgroundImage:
                'url(' +
                car.wheel[0].path +
                '/' +
                car.colors[0].key +
                '/' +
                '01.jpg' +
                ')',
            }"
          ></div> -->
          <div class="md-custom:hidden">
            <VersionColors v-bind:car="car" />
          </div>
        </figure>

        <div
          class="pl-3 contentIn pt-3 md-custom:items-center md-custom:flex flex-1"
        >
          <div>
            <VersionHeader v-bind:car="car" v-bind:name="name" />
          </div>
          <VersionEngine
            v-bind:car="car"
            class="md-custom:hidden"
            v-if="isDesktop"
            v-bind:name="name"
          />
        </div>
      </section>
      <section class="flex flex-col md-custom-min:w-6/12 w-full my-3 inner">
        <div class="px-4 h-full flex flex-col innerContainer">
          <div class="mobsecWrap md-custom-min:hidden md-custom:flex">
            <VersionEngineMobile v-bind:car="car" v-bind:name="name" />
          </div>

          <div class="md-custom-min:block md-custom:hidden">
            <VersionHighlight v-bind:car="car" />
          </div>
          <div class="md-custom-min:hidden md-custom:block mobsecWrap">
            <VersionHighlightMobile
              v-on:featuresClicked="featuresOpen()"
              v-bind:car="car"
            />
          </div>
          <div class="md-custom-min:hidden md-custom:block mobsecWrap">
            <VersionColorsMobile v-bind:car="car" />
          </div>
          <div class="md-custom-min:hidden md-custom:block mobsecWrap">
            <VersionPriceMobile v-bind:car="car" v-bind:name="name" />
          </div>

          <div
            class="flex justify-between items-center md-custom-min:items-end md-custom-min:h-full"
          >
            <FeaturesButton
              v-on:featuresClicked="featuresOpen()"
              class="md-custom-min:block md-custom:hidden"
            />

            <selectButton
              v-if="
                !changeAlreadySelected ||
                  getGradObj.Version_Label == car.Version_Label
              "
              class="md-custom-min:block md-custom:hidden"
              v-bind:car="car"
              v-bind:confirm="changeAlreadySelected"
              v-bind:carid="carid"
            />

            <ChoiceButton
              v-if="
                changeAlreadySelected &&
                  getGradObj.Version_Label != car.Version_Label
              "
              class="md-custom-min:block md-custom:hidden"
              v-on:choiceTriggered="choiceBoxOpen({ behavior: 'smooth' })"
              v-bind:car="car"
              v-bind:selectedcar="getGradObj"
              v-bind:carid="carid"
            />
          </div>
        </div>
        <div class="forMobile">
          <selectButton
            v-if="
              !changeAlreadySelected ||
                getGradObj.Version_Label == car.Version_Label
            "
            class="md-custom-min:hidden md-custom:block"
            v-bind:car="car"
            v-bind:carid="carid"
          />
          <ChoiceButton
            v-if="
              changeAlreadySelected &&
                getGradObj.Version_Label != car.Version_Label
            "
            class="md-custom-min:hidden md-custom:block"
            v-on:choiceTriggered="choiceBoxOpen({ behavior: 'smooth' })"
            v-bind:car="car"
            v-bind:carid="carid"
          />
        </div>
      </section>
      <ChoiceContentBox
        v-show="choiceComponent"
        v-on:closeChoiceClicked="closeChoice()"
        v-bind:car="car"
        v-bind:selectedcar="getGradObj"
        v-bind:carid="carid"
        v-bind:name="name"
      />
    </div>
    <Features
      v-if="featuresComponent"
      :type="'showFeatures'"
      v-on:closeModal="featuresOpen"
      :featuresIsOpen="featuresComponent"
      v-bind:car="car"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VersionHeader from "./versionItem/versionHeader";
import VersionEngine from "./versionItem/versionEngine";
import FeaturesButton from "./versionItem/featuresButton";
import selectButton from "./versionItem/selectButton";
import ChoiceButton from "./versionItem/choiceButton";
import ChoiceContentBox from "./versionItem/choiceContentBox";
import VersionEngineMobile from "./versionItem/versionEngineMobile";
import VersionHighlightMobile from "./versionItem/versionHighlightMobile";
import VersionPriceMobile from "./versionItem/versionPriceMobile";
import VersionColors from "./versionItem/versionColors";
import VersionColorsMobile from "./versionItem/versionColorsMobile";
import VersionHighlight from "./versionItem/versionHighlight";
import Features from "../../../components/sidePanel/panelView";
export default {
  props: ["car", "selectedModel", "name", "carid"],
  components: {
    VersionHeader,
    VersionEngine,
    FeaturesButton,
    selectButton,
    ChoiceButton,
    ChoiceContentBox,

    VersionEngineMobile,
    VersionHighlightMobile,
    VersionPriceMobile,
    VersionColors,
    VersionColorsMobile,
    VersionHighlight,
    Features,
  },
  data: function() {
    return {
      featuresComponent: false,
      choiceComponent: false,
      isDesktop: true,
      isActive: false,
    };
  },
  methods: {
    featuresOpen() {
      let root = document.getElementsByTagName("html")[0];
      if (this.featuresComponent == false) {
        this.featuresComponent = true;
        root.setAttribute("class", "overflow-y-hidden");
      } else {
        this.featuresComponent = false;
        root.classList.remove("overflow-y-hidden");
        //  console.log("false");
      }
    },

    choiceBoxOpen(options) {
      const el = this.$el.getElementsByClassName("choiceBtn")[0];
      // console.log(el);
      if (el) {
        el.scrollIntoView(options);
        this.choiceComponent = true;
      }
    },
    closeChoice() {
      this.choiceComponent = false;
    },
  },

  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    ...mapGetters(["getallCars"]),
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["changeAlreadySelected"]),
  },

  created() {},
};
</script>

<style scoped>
.car-wrapper {
  overflow: hidden;
}
.car-wrapper img {
  transform: scale(1.3);
  position: relative;
  top: 9px;
}
.heroItem {
  border: 1px solid #d9d9d9;
  background: #fff;
}
figure.hero {
  width: 45%;
  /* display: flex;
  flex-direction: column;
  height: 100%; */
}

.heroItem_en .inner:nth-child(2) {
  border-left: 1px solid #d9d9d9;
}
.heroItem_ar .inner:nth-child(2) {
  border-right: 1px solid #d9d9d9;
}
.featureBtn span i {
  color: #c3002f;
  font-size: 17px;
  margin-left: 6px;
}
.mobsecWrap {
  border-top: 1px solid #d9d9d9;
  padding-top: 0.71429em;
  padding-bottom: 0.71429em;
  position: relative;
}
@media (max-width: 960px) {
  .heroItem .inner {
    border-left: 0 !important;
    margin-bottom: 0;
  }
  /* .car-wrapper {
    height: 70px;
  } */
}
@media only screen and (device-width: 768px) {
  .car-wrapper {
    height: 145px;
  }
}
</style>
