<template>
  <div class="bg-bgBlack flex flex-wrap flex-col w-full p-3">
    <div class="text-lg font-semibold uppercase text-white pb-8">
      THIS CHOICE LEADS TO A CHANGE IN YOUR CONFIGURATION
    </div>
    <div class="flex md-custom:flex-col">
      <div class="flex flex-wrap flex-row md-custom-min:w-7/12 w-full">
        <div
          class="w-full flex flex-1 md-custom-min:pr-4 md-custom:flex-col section"
        >
          <div class="md-custom-min:w-6/12 w-full md-custom-min:pr-3">
            <h3 class="text-base text-white pb-3">REMOVE SELECTION</h3>
            <ul>
              <li>
                <span class="text-white text-sm leading-normal uppercase">{{
                  selectedcar.interior[0].name
                }}</span
                ><span class="text-white text-sm ml-3"></span>
              </li>
            </ul>
          </div>
          <div class="md-custom-min:w-6/12 w-full md-custom:mt-4">
            <h3 class="text-base text-white pb-3">REPLACE SELECTION</h3>
            <ul>
              <li>
                <span class="text-white text-sm leading-normal uppercase">{{
                  car.interior[0].name
                }}</span
                ><span class="text-white text-sm ml-3"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="md-custom-min:w-5/12 h-full w-full">
        <div
          class="w-full flex md-custom-min:px-4 flex-col sectionTotal md-custom:mt-4"
        >
          <div class="flex w-full justify-between priceLine text-base pb-3">
            <label class=" text-white">NEW TOTAL</label>
            <span class=" text-white"
              >{{ $t("CURRENCY") }}
              {{ Number(car.price).toLocaleString() }}</span
            >
          </div>
          <div class="flex mt-4">
            <button
              class="w-full buttonHero relative mr-3 bg-primaryGrey text-herored text-left text-sm hover:bg-secondaryGrey"
              @click="closeChoice()"
            >
              CANCEL<span class="angleLeft"
                ><i class="fas fa-angle-right text-herored"></i>
              </span>
            </button>
            <button
              class="w-full buttonHero relative bg-herored text-white text-left text-sm hover:bg-darkRed"
              @click="setSelectedObj([1, carid]), closeChoice(), tag()"
            >
              CONFIRM<span class="angleLeft"
                ><i class="fas fa-angle-right text-white"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["carid", "car", "selectedcar", "name"],

  data() {
    return {};
  },
  methods: {
    ...mapActions(["changePage"]),
    ...mapActions(["setSelectedObj"]),
    closeChoice() {
      this.$emit("closeChoiceClicked");
    },
    tag() {
      var model = this.$store.getters.getallCars.model;
      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        item_slection:
          "versions|" + this.$store.state.Cars.selectedGradeObj.Version_Label,
        type: "selection",
        buttonText: "Confirm",
        location: "cta",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
  },
  computed: {
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
  },
  components: {},
};
</script>

<style scoped>
li {
  padding: 0.78571em 10px;
  background-color: #343434;
  display: flex;
  justify-content: space-between;
}
.sectionTotal {
  border-left: 1px solid #4c4c4c;
}
.priceLine {
  border-bottom: 1px solid #4c4c4c;
}
@media (max-width: 960px) {
  .sectionTotal {
    border: none;
  }
}
</style>
