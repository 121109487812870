<template>
  <div class="popupWrap">
    <div class="popHeader">
      <div class="mb-3 heroHead">
        <h2 class="uppercase" v-if="getallCars.model">
          {{ getallCars.model.model }}
        </h2>
        <h4 v-if="getGradObj && getGradObj.technical_specs">
          {{ getGradObj.technical_specs.Engine_Type.values[0].value }}
          {{ getGradObj.Version_Label }}
        </h4>
      </div>
      <button class="flex items-center" v-on:click="popupClose()">
        <span><i class="fas fa-times text-2xl text-herored"></i></span>
      </button>
    </div>
    <div class="relative  flex-auto w-full h-full previewWrap ">
      <div
        class="absolute flex  items-center	  inset-0 w-full h-full cursor-grab"
      >
        <div class="In_Ex_View" v-if="Object.keys(getGradObj).length">
          <transition name="fade">
            <PreviewBox
              v-bind:grades="getGradObj"
              v-bind:selectedColor="selectedExtColor"
              v-if="exterior && !IsLoading"
            />
            <div class="fa-3x loader" v-if="IsLoading">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </transition>

          <Interior
            v-bind:grades="getGradObj"
            v-bind:selectedIntColor="selectedIntColor"
            v-if="interior"
          />
        </div>
      </div>
    </div>

    <div
      class="flex absolute w-full left-0 right-0 bottom-0"
      v-if="getallCars.model"
    >
      <button
        class="w-full inExBtn"
        v-bind:class="{ active: exterior }"
        v-on:click="exteriorView()"
      >
        {{ $t("VIEW_EXTERIOR") }}
      </button>
      <button
        class="w-full inExBtn"
        v-bind:class="{ active: interior }"
        v-on:click="interiorView()"
      >
        {{ $t("VIEW_INTERIOR") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreviewBox from "../../view/navSections/colors/360";
import Interior from "../../view/navSections/colors/interior";
export default {
  props: ["currentPage"],
  components: {
    PreviewBox,
    Interior,
  },
  data() {
    return {
      interior: this.currentPage == 2 ? true : false,
      exterior: this.currentPage != 2 ? true : false,
    };
  },
  computed: {
    IsLoading() {
      return this.$store.getters.isLoading;
    },

    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    selectedExtColorName() {
      return this.$store.getters.selectedColors;
    },
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["getallCars"]),
  },
  methods: {
    popupClose() {
      this.$emit("popupClosed");
    },
    exteriorView() {
      if (this.interior == true) {
        this.interior = false;
        this.exterior = true;
      }
    },

    interiorView() {
      if (this.exterior == true) {
        this.exterior = false;
        this.interior = true;
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.popupWrap {
  position: fixed;
  z-index: 22;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  overflow: hidden;
}
.popHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 1.07143em;
  padding-bottom: 1.07143em;
}
.heroHead h2 {
  font-size: 1.21em;
  font-weight: 600;
}
.heroHead h4 {
  font-weight: 300;
  font-size: 0.85em;
  margin-top: 5px;
  margin-bottom: 0.35714em;
}
.inExBtn {
  color: #343434;
  border: 1px solid #d2d2d2;
  background: #d2d2d2;
  padding: 0.85714em 15px;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3;
  position: relative;
}
.inExBtn:focus,
.inExBtn:active {
  outline: none;
}
.inExBtn.active {
  cursor: default;
  color: #fff;
  border: 1px solid #c3002f;
  background: #c3002f;
}
.inExBtn.active:before {
  position: absolute;
  left: calc(50% - 6px);
  top: -7px;
  display: block;
  width: 12px;
  height: 12px;
  margin: auto;
  content: "";
  background-color: inherit;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
