var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"optionChoices"},[_c('div',{staticClass:"choicesInner"},[_c('h2',{staticClass:"text-base font-semibold uppercase text-white"},[_vm._v(" "+_vm._s(_vm.$t("REMOVE_SELECTION"))+" ")]),_c('ul',{staticClass:"mt-4"},[_c('li',{staticClass:"w-full flex justify-between mb-1"},[(_vm.language == 'en')?_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.selectedcar.wheel[0].name)+" ")]):_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.selectedcar.wheel[0].name_ar)+" ")]),_c('span',{staticClass:"text-sm  text-white"})]),_c('li',{staticClass:"w-full flex justify-between mb-1"},[_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.selectedcar.Version_Label))]),_c('span',{staticClass:"text-sm  text-white flex"},[_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-1' : 'text-white order-2' ]},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-2' : 'text-white order-1' ]},[_vm._v(_vm._s(Number(_vm.selectedcar.price).toLocaleString())+" ")])])])])]),_c('div',{staticClass:"mt-4 choicesInner"},[_c('h2',{staticClass:"text-base font-semibold uppercase text-white"},[_vm._v(" "+_vm._s(_vm.$t("REPLACE_SELECTION"))+" ")]),_c('ul',{staticClass:"mt-4"},[_c('li',{staticClass:"w-full flex justify-between mb-1"},[(_vm.language == 'en')?_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.car.wheel.name))]):_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.car.wheel.name_ar))]),_c('span',{staticClass:"text-sm  text-white"})]),_c('li',{staticClass:"w-full flex justify-between mb-1"},[_c('span',{staticClass:"text-sm  text-white"},[_vm._v(_vm._s(_vm.car.Version_Label))]),_c('span',{staticClass:"text-sm flex  text-white"},[_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-1' : 'text-white order-2' ]},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[
              _vm.language == 'en' ? 'text-white order-2' : 'text-white order-1' ]},[_vm._v(_vm._s(Number(_vm.car.price).toLocaleString())+" ")])])])])]),_c('div',{staticClass:"mt-4 choicesInner"},[_c('h2',{staticClass:"flex justify-between text-base font-semibold uppercase pb-4 border-0 border-bw-1 border-solid border-gray-400"},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t("NEW_TOTAL")))]),_c('i',{staticClass:"text-white flex"},[_c('span',{class:[
            _vm.language == 'en' ? 'text-white order-1' : 'text-white order-2' ]},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[
            _vm.language == 'en' ? 'text-white order-2' : 'text-white order-1' ]},[_vm._v(_vm._s(Number(_vm.car.price).toLocaleString())+" ")])])]),_c('div',{staticClass:"flex justify-between mt-4 btnWrap"},[_c('button',{staticClass:"w-full text-sm font-normal uppercase   relative cancel",class:[_vm.language != 'ar' ? 'mr-4' : 'ml-4'],on:{"click":function($event){return _vm.CloseChoiceBox()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("CANCEL")))]),_c('i',{staticClass:"absolute  text-base",class:[
            _vm.language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left' ],style:(_vm.language != 'ar' ? 'right: 22px;' : 'left:22px;right:0px')})]),_c('button',{staticClass:"w-full text-sm font-normal uppercase relative   bg-herored text-white",on:{"click":function($event){return _vm.confirm(_vm.car)}}},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t("CONFIRM")))]),_c('i',{staticClass:"text-white absolute  text-base",class:[
            _vm.language != 'ar' ? 'fas fa-angle-right' : 'fas fa-angle-left' ],style:(_vm.language != 'ar' ? 'right: 22px;' : 'left:22px;')})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }