<template>
  <div
    class="popoverWrap"
    :style="
      language != 'ar' ? 'right: 15px; left: auto;' : 'left: 15px; right: auto;'
    "
  >
    <div class="inner">
      <button
        class="togglePop"
        @click="togglePop()"
        v-bind:class="[
          language != 'ar' ? 'togglePop_en' : 'togglePop_ar',
          isActive ? 'open' : 'close',
        ]"
      ></button>
      <div class="detailWrap" v-if="popIsOpen">
        <section :class="[language == 'en' ? 'section_en' : 'section_ar']">
          <h2
            class="text-base font-semibold uppercase"
            v-if="getallCars.model && language != 'ar'"
          >
            {{ getallCars.model.model }}
          </h2>
          <h2
            class="text-base font-semibold uppercase"
            v-else-if="getallCars.model && language == 'ar'"
          >
            {{ getallCars.model.model_ar }}
          </h2>

          <h4
            class="text-sm font-normal py-1"
            v-if="grades && grades.technical_specs && language != 'ar'"
          >
            {{
              grades.Version_Label.replace("4.0L V6 ", "").replace(
                "5.6L V8 400HP ",
                ""
              )
            }}
          </h4>
          <h4
            class="text-sm font-normal  py-1"
            v-if="grades && grades.technical_specs && language == 'ar'"
          >
            {{
              grades.Version_Label.replace("4.0L V6 ", "").replace(
                "5.6L V8 400HP ",
                ""
              )
            }}
          </h4>
          <!-- <a
             target="_self"
            v-if="
              getallCars.model &&
                getallCars.model.model.toLowerCase() != 'sunny' &&
                getallCars.model.model.toLowerCase() != 'x-terra'
            "
            class="uppercase outerLink"
            :class="[language == 'en' ? 'outerLink_en' : 'outerLink_ar']"
            :href="
              language == 'en'
                ? parent_url+'book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
                : parent_url+'book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
                  codes[getallCars.model.model.toLowerCase()]
            "
            ><span class="text-sm"> {{ $t("TEST_DRIVE") }}</span></a
          > -->
          <div
            :class="[language == 'en' ? 'cst_btn_wrap_en' : 'cst_btn_wrap_ar']"
          >
            <a
              target="_self"
              v-if="getallCars.model"
              class="uppercase outerLink"
              :class="[language == 'en' ? 'outerLink_en' : 'outerLink_ar']"
              :href="
                language == 'en'
                  ? parent_url +
                    'book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
                    codes[getallCars.model.model.toLowerCase()]
                  : parent_url +
                    'book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
                    codes[getallCars.model.model.toLowerCase()]
              "
              ><span class="text-sm"> {{ $t("TEST_DRIVE") }}</span></a
            >
          </div>
        </section>
        <section :class="[language == 'en' ? 'section_en' : 'section_ar']">
          <div class="text-xs font-normal priceIn">
            {{ $t("TOTAL_PRICE") }} *
          </div>
          <span class="block text-xl font-light mt-2 flex" v-if="grades">
            <span :class="[language == 'en' ? 'order-1 px-1' : 'order-2 px-1']">
              {{ $t("CURRENCY") }}</span
            >

            <span :class="[language == 'en' ? 'order-2' : 'order-1']">
              {{ Number(grades.price).toLocaleString() }}
            </span>
          </span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["grades"],
  data() {
    return {
      popIsOpen: true,
      isActive: true,
      codes: {
        altima: "L34",
        kicks: "P15",
        maxima: "A36",
        patrol: "Y62",
        sunny: "N18",
        "x-trail": "T32",
        "x-terra": "P60A",
      },
    };
  },
  computed: {
    ...mapGetters(["getallCars"]),
    ...mapGetters(["getGrade"]),
    ...mapGetters(["getGradObj"]),
    language() {
      return this.$store.state.Pages.language;
    },
    dealer() {
      const site = document.location.href.split("?")[1];
      return site.split("#")[0];
    },
    parent_url() {
      const site = document.location.href.split("?")[1];
      const goblal_site = site.split("#")[0];

      if (goblal_site == "site=ma") {
        // https://nissan-manahil.com/vehcles/maxima/confure/#maxima/S/QAB/I-UP1_C/4

        const lang =
          this.$store.state.Pages.language.trim() == "en" ? "en." : "www.";
        return "https://" + lang + "nissan-manahil.com/";
      } else {
        // https://nissan-manahil.com/vehcles/maxima/confure/#maxima/S/QAB/I-UP1_C/4

        const lang =
          this.$store.state.Pages.language.trim() == "en" ? "en." : "www.";
        return "https://" + lang + "petromin-nissan.com/";
      }
      //   const lang = this.$store.state.Pages.language.trim()=="en"?"en.":"www.";
      //    return "https://"+lang+"nissan-manahil.com/"+ model + '/configure/'+ this.$store.state.Pages.summaryUrl;
      // return document.referrer;
    },
  },

  methods: {
    togglePop() {
      this.popIsOpen = !this.popIsOpen;
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
.cst_btn_wrap_ar {
  display: block;
  float: right;
}
.cst_btn_wrap_en {
  display: block;
  float: left;
}
.popoverWrap {
  bottom: 1em;

  margin: auto;
  max-width: 75%;
  z-index: 1;
  position: fixed;
}
.inner {
  background: #fff;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  max-width: 550px;
  position: relative;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  width: 100%;
}
.togglePop {
  border: none;
  height: auto;
  padding: 0;
  background: #fff;
  color: #c1c1c1;
  width: 25px;
  min-height: 107.81px;
}
.togglePop::after {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  font-size: 22px;
}
.togglePop_en::after {
  content: "\f105";
}
.togglePop_ar::after {
  content: "\f104";
}
.togglePop.close {
  background: #eee;
}
.togglePop_en.close::after {
  content: "\f104";
}
.togglePop_ar.close::after {
  content: "\f105";
}
.togglePop:hover {
  background: #c3002f;
  color: #fff;
}
.togglePop:focus {
  outline: none;
}
.detailWrap {
  transition: opacity 0.3s ease-in-out;
  display: flex;
  width: calc(100% - 25px);
}
.section_en {
  display: inline-block;
  padding: 0.8571428571em 15px;
  border-left: 1px solid #c1c1c1;
}
.section_ar {
  display: inline-block;
  padding: 0.8571428571em 15px;
  border-right: 1px solid #c1c1c1;
}
.outerLink_en {
  padding: 0.8571428571em 45px 0.8571428571em 20px;
  background: #eee;
  color: #000;
  border: 0;
  display: flex;
  margin-top: 9px;
  position: relative;
  align-items: center;
  width: 100%;
}
.outerLink_ar {
  padding: 0.8571428571em 20px 0.8571428571em 45px;
  background: #eee;
  color: #000;
  border: 0;
  width: 100%;
  display: flex;
  margin-top: 9px;
  position: relative;
  align-items: center;
}
.outerLink::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  position: absolute;

  color: #c3002f;
}
.outerLink_en::after {
  content: "\f105";
  right: 20px;
}
.outerLink_ar::after {
  content: "\f104";
  left: 20px;
}
</style>
