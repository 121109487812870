<template>
  <div>
    <div class="tabContainer">
      <nav>
        <UtilityBar />
        <div class="navContainer">
          <ul class="grid  grid-cols-5 gap-0 heroNav">
            <li
              v-for="(navTab, index) in navTabs"
              :key="index"
              @click="changePage(index)"
              :class="[
                { active: currentPage == index },
                language != 'ar' ? 'en' : 'ar',
              ]"
              class="uppercase font-medium text-base"
            >
              <div
                class="flex justify-center items-center cursor-pointer listItem"
              >
                <i
                  class="fa fa-check"
                  aria-hidden="true"
                  :style="
                    language != 'ar'
                      ? 'margin-right: 11.55px'
                      : 'margin-left: 11.55px'
                  "
                ></i>
                <div class="innerTxt">
                  <span> {{ $t(navTab) }}</span>
                  <span class="selectedSpec" v-if="index == 0">
                    {{ getGradObj.Version_Label }}
                  </span>
                  <span
                    class="selectedSpec"
                    v-if="index == 1 && language != 'ar'"
                  >
                    {{ selectedColors.name }}
                  </span>
                  <span
                    class="selectedSpec"
                    v-if="index == 1 && language == 'ar'"
                  >
                    {{ selectedColors.name_ar }}
                  </span>

                  <span
                    class="selectedSpec"
                    v-if="index == 2 && language != 'ar'"
                  >
                    {{ selectedIntColors.name }}
                  </span>

                  <span
                    class="selectedSpec"
                    v-if="index == 2 && language == 'ar'"
                  >
                    {{ selectedIntColors.name_ar }}
                  </span>

                  <span
                    class="selectedSpec"
                    v-if="index == 3 && language != 'ar'"
                  >
                    {{ selectedWheelName.name }}
                  </span>
                  <span
                    class="selectedSpec"
                    v-if="index == 3 && language == 'ar'"
                  >
                    {{ selectedWheelName.name_ar }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div class="tabContent">
        <div v-show="currentPage == 0">
          <Version />
        </div>

        <div v-show="currentPage == 1">
          <Colors />
        </div>

        <div v-show="currentPage == 2">
          <Upholstery />
        </div>

        <div v-show="currentPage == 3">
          <Wheels />
        </div>

        <div v-show="currentPage == 4">
          <Summary />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import UtilityBar from "./utilityBar";
import Version from "../navSections/version/version";
import Colors from "../navSections/colors/colors.vue";
import Upholstery from "../navSections/upholstery/upholstery.vue";
import Summary from "../navSections/summary/summary.vue";
import Wheels from "../navSections/wheels/wheels.vue";
export default {
  data() {
    return {
      navTabs: "",
    };
  },

  computed: {
    custom() {
      var allcars = JSON.parse(JSON.stringify(this.$store.getters.getallCars));
      var model = allcars.model;
      return this.navTabs.filter((element, index) => {
        if (model != undefined) {
          console.log(model, element);
          if (model.model == "Patrol" && index == 2) return;
        } else {
          return element;
        }
        return element;
      });
    },
    ...mapGetters(["getallCars"]),
    ...mapGetters(["currentPage"]),
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["selectedColors"]),
    ...mapGetters(["selectedIntColors"]),

    selectedWheelName() {
      return this.$store.state.Pages.selectedWheelName;
    },
    language() {
      return this.$store.state.Pages.language;
    },
  },

  created() {
    if (this.$store.state.Pages.language == "en") {
      this.navTabs = [
        "Version",
        "Colours",
        "Upholstery",
        "Wheels",
        "car summary",
      ];
    } else {
      this.navTabs = ["الطراز", "اللون", "الفرش", "العجلات", "ملخص التخصيصات"];
    }
    document.body.classList.add("enableScroll");
    window.addEventListener("resize", this.resizeHandler);
  },

  mounted() {
    this.getSummaryIcon();
  },

  methods: {
    // ...mapActions(["changePage"]),
    changePage: function(value) {
      this.$store.dispatch("changePage", value);
      var page =
        this.$store.state.Pages.currentPage == 4
          ? "complete"
          : this.$store.state.Pages.currentPage == 0
          ? "versions"
          : this.$store.state.Pages.currentPage == 1
          ? "colours"
          : this.$store.state.Pages.currentPage == 2
          ? "upholstery"
          : this.$store.state.Pages.currentPage == 3
          ? "wheels"
          : "";
      var analytics = {
        language: this.$store.state.Pages.language,
        model: this.$store.state.Cars.cars.model.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: page,
        buttonText: "nav-bar",
        location: "nav-bar",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
    getSummaryIcon() {
      let summaryicon =
        "<div class='summaryIcon'><i class='fas fa-file-alt'></i></div>";
      if (window.matchMedia("(max-width: 959px)").matches) {
        let parent = (document.querySelector(
          ".navContainer ul li:last-child"
        ).innerHTML = summaryicon);
        return parent;
      }
    },

    resizeHandler() {
      this.getSummaryIcon();
    },
  },

  watch: {
    currentPage() {
      //console.log("this.currentPage");

      let el = document.body;

      if (this.currentPage == 0 || this.currentPage == 4) {
        el.classList.remove("disableScroll");
        el.classList.add("enableScroll");
      } else {
        window.scrollTo(0, 0);
        el.classList.remove("enableScroll");
        el.classList.add("disableScroll");
      }
    },
  },

  components: {
    UtilityBar,
    Version,
    Colors,
    Upholstery,
    Summary,
    Wheels,
  },
};
</script>
<style scoped>
nav {
  display: block;
  background-color: #f9f9f9;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 11;
}
ul .listItem {
  color: #666;
  padding: 0.94em 0em;
  height: 100%;
}
ul .listItem .innerTxt {
  display: inline-block;
}
ul .listItem .innerTxt span:nth-child(2),
ul .listItem .innerTxt span span {
  font-size: 12px;
  letter-spacing: 1px;
  color: #666;
  margin-top: 3px;
}
.heroNav li {
  transition: all ease-out 0.25s;
  border-bottom: 4px solid transparent;
}
.heroNav li:hover,
.heroNav li:focus {
  border-color: #c3092f;
}
.heroNav li.active {
  color: #000000 !important;
  border-color: #c3092f;
}
.heroNav li.active i {
  color: #000000 !important;
}
ul .listItem .innerTxt span {
  display: block;
  font-size: 14px;
  letter-spacing: 0.85px;
}
ul .listItem i {
  font-size: 19px;

  display: inline-block;
  vertical-align: top;
  color: #666;
}
.router-link-exact-active {
  color: #000000 !important;
  border-bottom: 4px solid #c3092f;
}
.router-link-active i {
  color: #000000 !important;
}
.selectedSpec {
  max-width: calc(20rem - 24px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.summaryIcon {
  display: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.tabContent {
  /* padding-top: 52px; */
  padding-top: 93px;
}
.addScroll {
  overflow: scroll !important;
}
@media screen and (max-width: 959px) {
  .selectedSpec {
    max-width: calc(10rem - 24px);
  }
  ul .listItem i {
    display: none;
  }
  .summaryIcon {
    display: flex;
  }
  .navContainer ul li:last-child .innerTxt {
    display: none;
  }
  .summaryIcon i {
    display: block !important;
  }
  .navContainer ul li.ar:last-child {
    left: 0;
  }
  .navContainer ul li.en:last-child {
    right: 0;
  }
  .navContainer ul li:last-child {
    z-index: 12;
    position: fixed;
    top: inherit;

    width: 60px;
    height: 65px;
    background: #f9f9f9;
    min-width: 60px;
  }
  .heroNav {
    grid-column: 1 / -1;
    grid-template-columns: repeat(5, calc(28% - 20px * 2));
    /* grid-template-rows: minmax(150px, 1fr); */
    overflow: hidden;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    width: calc(100% - 60px);
    height: 65px;
  }
  .heroNav::-webkit-scrollbar {
    display: none;
  }
  .heroNav li {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  /* ul .listItem {
    height: auto;
  } */
}
@media screen and (max-width: 668px) {
  .heroNav {
    grid-template-columns: repeat(5, calc(199px - 20px * 2));
  }
}
@media screen and (max-width: 480px) {
  .heroNav {
    grid-template-columns: repeat(5, calc(60% - 20px * 2));
  }
}
</style>
