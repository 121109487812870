var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"priceName"},[_c('div',{staticClass:"pt-3 px-3"},[_c('div',{staticClass:"w-full mb-3 heroHead"},[(_vm.getallCars.model)?_c('h2',{staticClass:"uppercase"},[(_vm.language != 'ar')?_c('span',[_vm._v(" "+_vm._s(_vm.getallCars.model.model)+" ")]):_c('span',[_vm._v(_vm._s(_vm.getallCars.model.model_ar))])]):_vm._e(),(_vm.getGradObj && _vm.getGradObj.technical_specs && _vm.language != 'ar')?_c('h4',[_vm._v(" "+_vm._s(_vm.getGradObj.technical_specs.Engine_Type.values[0].value)+" "+_vm._s(_vm.getGradObj.Version_Label)+" ")]):(
          _vm.getGradObj && _vm.getGradObj.technical_specs && _vm.language == 'ar'
        )?_c('h4',[_vm._v(" "+_vm._s(_vm.getGradObj.technical_specs.Engine_Type.values[0].value_ar)+" "+_vm._s(_vm.getGradObj.Version_Label)+" ")]):_vm._e()]),_c('div',{staticClass:"w-full mb-3"},[_c('span',{staticClass:"priceLabel"},[_vm._v(_vm._s(_vm.$t("TOTAL_PRICE")))]),(_vm.getGradObj)?_c('div',{staticClass:"priceInn flex"},[_c('span',{class:[_vm.language == 'en' ? 'order-1' : 'order-2']},[_vm._v(_vm._s(_vm.$t("CURRENCY")))]),_c('span',{class:[_vm.language == 'en' ? 'order-2' : 'order-1']},[_vm._v(_vm._s(Number(_vm.getGradObj.price).toLocaleString())+" ")]),_c('span',{class:[_vm.language == 'en' ? 'order-2' : 'order-3']},[_vm._v("*")])]):_vm._e()]),(
        _vm.getallCars.model &&
          _vm.getallCars.model.model.toLowerCase() != 'sunny' &&
          _vm.getallCars.model.model.toLowerCase() != 'x-terra'
      )?_c('a',{staticClass:"flex items-center text-xs outerLink uppercase",attrs:{"href":_vm.language == 'en'
          ? 'https://en.petromin-nissan.com/book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
            _vm.codes[_vm.getallCars.model.model.toLowerCase()]
          : 'https://petromin-nissan.com/book-a-test-drive/?cmpT=riyadh.stand&vehicle=' +
            _vm.codes[_vm.getallCars.model.model.toLowerCase()]}},[_vm._v(" "+_vm._s(_vm.$t("TEST_DRIVE"))+" "),_c('i',{staticClass:"fas  text-base text-herored",class:[
          _vm.language != 'ar'
            ? 'fas fa-angle-right ml-2 '
            : 'fas fa-angle-left mr-2' ]})]):(_vm.getallCars.model)?_c('a',{staticClass:"flex items-center text-xs outerLink uppercase",attrs:{"href":_vm.language == 'en'
          ? 'https://en.nissan-manahil.com/book-a-test-drive/?cmpT=CompareModels.iFrame&vehicle=' +
            _vm.codes[_vm.getallCars.model.model.toLowerCase()]
          : 'https://nissan-manahil.com/book-a-test-drive/?cmpT=CompareModels.iFrame&vehicle=' +
            _vm.codes[_vm.getallCars.model.model.toLowerCase()]}},[_vm._v(" "+_vm._s(_vm.$t("TEST_DRIVE"))+" "),_c('i',{staticClass:"  text-base text-herored ",class:[
          _vm.language != 'ar'
            ? 'fas fa-angle-right ml-2 '
            : 'fas fa-angle-left mr-2' ]})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }