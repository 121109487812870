<template>
  <div class="featureWrap md-custom-min:overflow-hidden">

    <div
      class="featureView"
      v-bind:class="{ active: featuresComponent }"
      :style="language != 'ar' ? 'right:0;left:auto;' : 'left:0;right:auto;'"
    >
      <div class="flex justify-end pr-6 pt-6">
        <button class="flex items-center" v-on:click="modalClose()">
          {{ $t("CLOSE") }}
          <span class="ml-3"><i class="fas fa-times text-3xl"></i></span>
        </button>
      </div>
      <div class="viewContainer" v-if="type == 'showFeatures'">
        <div class="flex justify-center text-2xl font-semibold mb-10">
          {{
            car.Version_Label.replace("4.0L V6 ", "").replace(
              "5.6L V8 400HP ",
              ""
            )
          }}
        </div>
        <div class="flex justify-center text-2xl font-semibold mb-10">
          {{ $t("ALLFEATURES") }}
        </div>
        <div class="accordinBody">
          <Accordin
            v-for="item in car.equipments"
            :key="item.id"
            v-bind:title="item"
          >
            <span name="header" class="text-base font-semibold"></span>
            <ul>
              <li
                class="flex items-center relative px-2"
                v-for="itemVal in item.values"
                :key="itemVal.id"
              >
                <i :style="language != 'ar' ? '  left: 0;' : 'right:0'"></i
                ><span class="pl-3" v-if="language == 'en'">{{
                  itemVal.name
                }}</span>
                <span class="pr-3" v-else>{{ itemVal.name_ar }}</span>
              </li>
            </ul>
          </Accordin>
        </div>
      </div>
      <!-- share -->
      <div class="viewContainer forSummary" v-else-if="type == 'share'">
          
        <div class="flex justify-start text-3xl font-semibold mb-6 pl-12">
          {{ $t("SHARE") }}
        </div>
        <div class="text-base font-light text-center mb-5">
          {{ $t("SHARE_DESCRIPTION1") }}
        </div>
      
        <div class="modal-form">
          <div class="modal-form-input-group">
            <input type="text" :value="page_url" ref="txtBox" />
            <button class="buttonURL" @click="select()">
              <span class="button-standard-label text-white uppercase">{{
                $t("SELECT_URL")
              }}</span>
              <span :class="[language == 'en' ? 'pl-3' : 'pr-3']">
                <i
                  class="text-white text-base"
                  :class="[
                    language == 'en'
                      ? 'fas fa-angle-right '
                      : 'fas fa-angle-left ',
                  ]"
                ></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <!-- save -->
      <div class="viewContainer forSummary" v-else-if="type == 'save'">
        <div class="flex justify-start text-3xl font-semibold mb-6 pl-12">
          {{ $t("SAVE") }}
        </div>
        <div class="text-base font-light text-center mb-5">
          {{ $t("SAVE_DESCRIPTION1") }}
        </div>
        <div class="modal-form">
          <div class="modal-form-input-group">
            <input type="text" :value="page_url" ref="txtBox" />
            <button class="buttonURL" @click="select()">
              <span class="button-standard-label text-white uppercase">{{
                $t("SELECT_URL")
              }}</span>
              <span :class="[language == 'en' ? 'pl-3' : 'pr-3']">
                <i
                  class="text-white text-base"
                  :class="[
                    language == 'en'
                      ? 'fas fa-angle-right '
                      : 'fas fa-angle-left ',
                  ]"
                ></i>
              </span>
            </button>
          </div>
        </div>
        <div class="text-base font-light text-center mt-5">
          {{ $t("SAVE_DESCRIPTION2") }}
        </div>
      </div>
      <div
        class="viewContainer text-center forSummary forReset"
        v-else-if="type == 'reset'"
      >
        <div class="flex justify-start text-3xl font-semibold mb-10 header">
          {{ $t("RESET_CONFIGURATION") }}
        </div>
        <div class="containerReset">
          <i class="fas fa-undo-alt text-5xl font-semibold"></i>
          <div class="text-2xl font-semibold text-center">
            {{ $t("MESSAGE") }}
          </div>
          <button
            @click="
              setSelectedObj([0, Object.keys(getallCars.grades)[0]]),
                reset(),
                modalClose()
            "
            class="text-center text-sm font-normal bg-herored text-white relative uppercase mt-12 buttonHero"
          >
            {{ $t("RESET_CONFIGURATION") }}

            <span class="pl-3">
              <i
                class="fas fa-angle-right text-white text-base align-middle"
              ></i>
            </span>
          </button>
          <div class="justify-center mt-8 checkboxHero">
            <input id="not_show" type="checkbox" />
            <label for="not_show">
              <span class="text-sm pl-2 uppercase font-semibold">{{
                $t("DO_NOT_SHOW")
              }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordin from "../accordin/accordinBase";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Accordin,
  },
  props: ["featuresIsOpen", "car", "extColor", "type"],
  data: function() {
    return {
      featuresComponent: true,
      hasError: false,
      isActive: false,
    };
  },
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
    page_url() {
      const site = document.location.href.split("?")[1];
      const goblal_site = site.split("#")[0];
      const model = this.$store.state.Pages.summaryUrl.split('/')[0].replace("#","")
      console.log(model,'model')
      if(goblal_site=="site=ma")
      {
        // https://nissan-manahil.com/vehcles/maxima/confure/#maxima/S/QAB/I-UP1_C/4
        console.log((this.$store.state.Pages.language.trim()=="en")?"en.":"www.")
        const lang = this.$store.state.Pages.language.trim()=="en"?"en.":"www.";
         return "https://"+lang+"nissan-manahil.com/vehicles/"+ model + '/configure/'+ this.$store.state.Pages.summaryUrl;
      }else{
         // https://nissan-manahil.com/vehcles/maxima/confure/#maxima/S/QAB/I-UP1_C/4
        console.log((this.$store.state.Pages.language.trim()=="en")?"en.":"www.")
        const lang = this.$store.state.Pages.language.trim()=="en"?"en.":"www.";
         return "https://"+lang+"petromin-nissan.com/vehicles/"+ model + '/configure/'+ this.$store.state.Pages.summaryUrl;
      }
     
     
    },
    ...mapGetters(["getallCars"]),
  },
  methods: {
    select() {
      this.$refs.txtBox.focus();
      this.$refs.txtBox.select();
      var Url = this.$refs.txtBox;
      Url.innerHTML = window.location.href;
      Url.select();
      document.execCommand("copy");
    },
    ...mapActions(["setSelectedObj"]),
    ...mapActions(["reset"]),

    modalClose: function() {
      this.$emit("closeModal");
      //this.featuresComponent = false;
    },
  },

  created() {
    //this.featuresComponent = this.featuresIsOpen;
  },
};
</script>
<style scoped>
.featureWrap {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  /* overflow: hidden;
    overflow: auto;
   */
}
.featureView {
  position: absolute;
  bottom: 0;

  top: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 17;
  max-width: 67%;
  text-align: left;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(100%);
}
.featureView.active {
  transform: translateX(0%);
}
.text-danger {
  display: none;
}
.viewContainer {
  position: relative;
  height: calc(100vh - 54.1px);
  padding-top: 4.28571em;
  padding-bottom: 4.28571em;
  padding-right: 60px;
  padding-left: 60px;
  overflow: hidden;
  overflow-y: scroll;
}
.accordinBody .accordion {
  max-width: 700px;
  margin: auto;
}
ul li i {
  display: block;
  position: absolute;
  border-radius: 50%;

  top: 5px;
  width: 5px;
  height: 5px;
  background: #000;
}
ul li {
  margin-bottom: 1em;
  font-size: 14px;
  line-height: 1.3;
}
.forReset i.fa-undo-alt {
  margin: 0 auto;
  margin-bottom: 0.71429em;
  display: table;
  color: #c1c1c1;
}
label span {
  color: #767676;
}
.socialLink button {
  width: 40px;
  height: 40px;
  color: #fff;
  margin-right: 9px;
}
.viewContainer.forSummary {
  padding-top: 1.5em;
}
.containerReset,
.forReset .header {
  max-width: 700px;
  margin: auto;
}
.forReset .header {
  margin-bottom: 2.5rem !important;
}
.modal-form {
  width: 500px;
  margin: 0 auto;
}
.modal-form-input-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.modal-form-input-group input {
  flex: 1;
  padding: 0.99em 20px;
  border: 1px solid #c1c1c1;
  height: 3.27em;
  line-height: 1.07143;
  font-size: 1.07143em;
  font-weight: 600;
}
.modal-form-input-group .buttonURL {
  padding: 16.1px;
  position: relative;
  /* height: 3.27em; */
  line-height: 1.07143;
  font-size: 0.911em;
  color: #fff;
  border: 1px solid #c3002f;
  background: #c3002f;
  align-items: center;
}
@media (max-width: 960px) {
  .featureView {
    max-width: 95%;
  }
}
@media (max-width: 668px) {
  .featureView {
    max-width: 100%;
  }
  .modal-form {
    width: 100%;
  }
  .modal-form-input-group .buttonURL {
    padding: 11px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .modal-form-input-group {
    width: 100%;
    flex-wrap: nowrap;
    display: block;
  }
  .modal-form-input-group input {
  }
  input[type="text"] {
    width: 100%;
  }
  .viewContainer {
    padding-top: 1.28571em;
    padding-bottom: 2.28571em;
    padding-right: 15px;
    padding-left: 15px;
  }
  .body-inner ul {
    padding-left: 7px;
    padding-right: 7px;
  }
}
</style>
