<template>
  <div>
    <div
      class="flex md-custom-min:flex-row md-custom:flex-col-reverse flex-wrap overflow-hidden w-full  md-custom:pb-10 colorsWrap"
      :style="{ height: filterSectionHeight }"
    >
      <section
        class="flex flex-col upholsteryLeft"
        v-show="clothComponent"
        :clothIsOpen="clothComponent"
      >
        <div
          class="relative overflow-x-hidden md-custom-min:overflow-y-scroll p-5 w-full innerLeft"
          :style="{ height: filterSectionHeight }"
        >
          <ClothBox />
        </div>
        <div class="flex w-full fixed nextBtn">
          <button
            @click="changePage(3), tag()"
            class="w-full h-full relative bg-herored text-white text-left text-sm uppercase"
          >
            <div class="text-white comingTabHead">
              {{ $t("NEXT") }} :
              <span
                class="text-white  font-semibold"
                :class="[language == 'en' ? 'ml-1' : 'mr-1']"
                >{{ $t("TABS.WHEELS") }}</span
              >
            </div>
            <i
              class=" text-white"
              :class="[
                language != 'ar' ? 'fas fa-angle-right ' : 'fas fa-angle-left ',
              ]"
              :style="language != 'ar' ? '  right: 21px;' : 'left:21px'"
            ></i>
          </button>
        </div>
      </section>
      <section class="flex-auto relative bg-white md-custom:hidden">
        <div class="flex-auto w-full h-full previewWrap">
          <div class="absolute inset-0 w-full h-full cursor-grab">
            <div
              class="In_Ex_View h-full"
              v-if="Object.keys(getGradObj).length"
            >
              <transition name="fade">
                <PreviewBox
                  v-bind:grades="getGradObj"
                  v-bind:selectedColor="selectedExtColor"
                  v-if="exterior && !IsLoading"
                />
                <div class="fa-3x loader" v-if="IsLoading">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
              </transition>
              <Interior
                v-if="interior && currentPage == 2 && !isLoadInterior"
                v-bind:grades="getGradObj"
                v-bind:selectedIntColor="selectedIntColor"
              />
            </div>

            <div
              class="toggleBttns"
              :style="language != 'ar' ? 'left: auto;right:0' : 'right: auto;'"
            >
              <ViewSwitch @click.native="toggleView()" />

              <InExView @click.native="swapComponent" :page="'upholstery'" />
            </div>
            <Popover v-bind:grades="getGradObj" />
          </div>
        </div>
      </section>
      <section class="flex  md-custom-min:hidden md-custom:flex">
        <div class="w-2/4 wrapSec">
          <div class="inner">
            <NamePrice />
          </div>
        </div>
        <div class="w-2/4 wrapSec">
          <div class="inner">
            <ImageThumbnail v-on:popupClicked="popupOpen()" />
          </div>
        </div>

        <VehiclePopup
          v-if="vehiclePopup"
          v-on:popupClosed="popupClose()"
          :currentPage="currentPage"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ClothBox from "./clothBox";
import PreviewBox from "../colors/360";
import Interior from "../colors/interior";
import ViewSwitch from "../../../components/toggleButtons/toggleView";
import InExView from "../../../components/toggleButtons/in_exView";
import NamePrice from "../../../components/smallScreen360-carDetails/namePrice";
import ImageThumbnail from "../../../components/smallScreen360-carDetails/imageThumbnail";
import VehiclePopup from "../../../components/smallScreen360-carDetails/vechiclePopup";
import Popover from "../../../components/pricePopover/detail";
export default {
  data() {
    return {
      interior: true,
      exterior: false,

      clothComponent: true,
      vehiclePopup: false,
      loading: true,
    };
  },
  computed: {
    filterSectionHeight() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!isMobile) {
        var width = document.body.clientWidth;
        return width / 1.77777777778 / 1.7 + "px";
      } else {
        return "100%";
      }
    },
    language() {
      return this.$store.state.Pages.language;
    },
    ...mapGetters(["getGradObj"]),
    ...mapGetters(["currentPage"]),

    IsLoading() {
      return this.$store.getters.isLoading;
    },
    isLoadInterior() {
      return this.$store.getters.isLoadInterior;
    },

    selectedExtColor() {
      var OBJ = this.$store.getters.selectedColors;
      let path = OBJ.ext_path;
      let key = OBJ.key;
      return path + "/" + key;
    },
    selectedIntColor() {
      return this.$store.getters.selectedIntColors;
    },
    selectedExtColorName() {
      return this.$store.getters.selectedColors;
    },
  },
  methods: {
    filterSectionHeights() {
      var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (!isMobile) {
        var width = document.body.clientWidth;
        return width / 1.77777777778 / 1.7 + "px";
      } else {
        return "100%";
      }
    },
    ...mapActions(["changePage"]),
    tag() {
      var analytics = {
        language: this.$store.state.Pages.language,
        model: this.$store.state.Cars.cars.model.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: this.$store.state.Pages.currentPage,
        type: "wheels",
        buttonText: "wheels",
        location: "cta",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
    },
    toggleView() {
      this.clothComponent = !this.clothComponent;
    },

    swapComponent() {
      if (this.interior == true) {
        this.interior = false;
        this.exterior = true;
      } else {
        this.interior = true;
        this.exterior = false;
      }
    },
    popupOpen() {
      this.vehiclePopup = !this.vehiclePopup;
    },
    popupClose() {
      this.vehiclePopup = !this.vehiclePopup;
    },
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 200);
    window.addEventListener("resize", this.filterSectionHeights);
  },
  destroyed() {
    window.removeEventListener("resize", this.filterSectionHeights);
  },

  components: {
    Popover,
    ClothBox,
    PreviewBox,
    Interior,
    ViewSwitch,
    InExView,
    NamePrice,
    ImageThumbnail,
    VehiclePopup,
  },
};
</script>

<style scoped>
.colorsWrap section.upholsteryLeft {
  flex: 0 0 385px;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.wrapSec {
  border-bottom: 1px solid #c1c1c1;
  background: #fff;
}
.innerLeft {
  position: relative;
  padding-bottom: 161px;
  top: 0;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.nextBtn {
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 385px;
  height: 43.97px;
  width: 100%;
  z-index: 1;
}
button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.85714em 20px;
}
button > div {
  display: flex;
}
button i {
  position: absolute;

  font-size: 18px;
}
@media (max-width: 960px) {
  .nextBtn {
    max-width: 100%;
  }
}
</style>
