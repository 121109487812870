<template>
  <div class="pt-3 h-full">
    <div class="font-semibold highlights">
      <!-- {{car.equipments[Object.keys(car.equipments)[0]].values}} -->
      <div class="flex justify-between header">
        <div class="title">{{ $t("HIGHLIGHTS") }}</div>
      </div>
      <ul class="mt-4" v-if="car.USPS">
        <li class="relative" v-for="(higts, index) in car.USPS" :key="index">
          <span class="opacity-75"
            ><i class="fa fa-check" aria-hidden="true"></i
          ></span>
          <div class="font-light pl-5" v-if="language != 'ar'">
            {{ higts.name }}
          </div>
          <div class="font-light pr-5" v-else>
            {{ higts.name_ar }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["car"],
  components: {},
  computed: {
    language() {
      return this.$store.state.Pages.language;
    },
  },
};
</script>

<style scoped>
ul {
  margin-bottom: 1.42857em;
}
ul li span {
  position: absolute;
}
ul li {
  font-size: 12px;
  margin-bottom: 13px;
}
</style>
