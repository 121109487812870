<template>
  <div class="toggleBtn">
    <button class="toggleView" v-if="page != 'upholstery'" @click="toggleView">
      <div class="btnContent" v-if="IsOpen">
        <div class="wrapInner">
          <span class="txt view">{{ $t("VIEW_INTERIOR") }}</span>
          <img src="@/assets/img/stearing.png" class="stear" alt="" />
        </div>
      </div>
      <div class="btnContent" v-else>
        <div class="wrapInner">
          <span class="txt hide">{{ $t("VIEW_EXTERIOR") }}</span>
          <img src="@/assets/img/car.png" class="stear" alt="" />
        </div>
      </div>
    </button>
    <button class="toggleView" v-else @click="toggleView">
      <div class="btnContent" v-if="!IsOpen">
        <div class="wrapInner">
          <span class="txt view"> {{ $t("VIEW_INTERIOR") }}</span>
          <img src="@/assets/img/stearing.png" class="stear" alt="" />
        </div>
      </div>
      <div class="btnContent" v-else>
        <div class="wrapInner">
          <span class="txt hide"> {{ $t("VIEW_EXTERIOR") }}</span>
          <img src="@/assets/img/car.png" class="stear" alt="" />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["page"],
  data() {
    return {
      IsOpen: true,
    };
  },
  computed: {
    ...mapGetters(["getallCars"]),
  },

  methods: {
    toggleView() {
      var model = this.$store.getters.getallCars.model;

      const analytics = {
        language: this.$store.state.Pages.language,
        model: model?.model,
        object: this.$store.state.Cars.selectedGradeObj,
        currentpage: 1,
        type: "in_ext",
        buttonText: "interior view",
        location: "interior view",
        exteriorColor: this.$store.state.Pages.selectedExtColors,
        interiorColor: this.$store.state.Pages.selectedIntColors,
        wheel: this.$store.state.Pages.selectedWheelName,
      };
      this.$helpers.tag(analytics);
      this.IsOpen = !this.IsOpen;
    },
  },
};
</script>

<style scoped>
.toggleBtn {
  width: 100%;
  display: block;
  position: relative;
  height: 100%;
}
.btnContent {
  transition: width 0.4s ease-in-out;
  position: absolute;
  display: block;
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  right: 1px;
  top: 15px;
  cursor: pointer;
}
.btnContent i,
.btnContent .stear {
  position: absolute;
  margin: auto;
  font-size: 18.1px;
  color: #000;
  right: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stear {
  width: 20.1px;
}
.txt {
  top: 8px;
  left: auto;
  right: 0px;
  padding: 0.75rem 0;
  color: #333;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  bottom: 0;
  position: absolute;
  width: 111px;
  margin-right: 49px;
}
.toggleView:hover .btnContent {
  width: 10.88em;
}
.toggleView:hover .txt {
  opacity: 1;
}
.wrapInner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
}
</style>
