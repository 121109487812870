<template>
  <div id="app">
    <div class="appWrapper" ref="wrapper">
      <div class="appInnerHero">
        <div class="appContainerHero">
          <!-- <div class="top-buttons ">
            <ul>
              <li>Print</li>
              <li>Save</li>
              <li>Share</li>
            </ul>
          </div> -->
          <div class="router-bx-1">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
@import url("./assets/css/reset.css");
@import url("./assets/css/app.css");
</style>
